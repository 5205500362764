import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {HeadingPrimary} from '../components/headings'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import PriceListSection from '../components/sections/PriceListSection'
import FreeContractSection from '../components/sections/FreeContractSection'
import {connect} from 'react-redux'
import {fetchPricelists, fetchPageDetails} from '../actions'
import {BASE_URL} from '../path'

class ContractDocumentPricelist extends React.Component{

    componentDidMount(){
        if(!this.props.pdfs){
            this.props.fetchPricelists(null)
        }
        this.props.fetchPageDetails('services')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderJbccButton(){
        return this.props.pdfs.data.map((item, index) => {
            if(item.description === 'JBCC CONTRACT DOCUMENTS'){
                return (
                    <React.Fragment key={index}>
                        <a className="mb_button-gold-fill" href={item.download_pdf ? `${BASE_URL}${item.download_pdf}` : '/'} target="_blank" title="JBCC PRICELISTS" el="noopener noreferrer">{item.download_title}</a>
                    </React.Fragment>
                )
            }       
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderMbsaButton(){
        return this.props.pdfs.data.map((item, index) => {
            if(item.description === 'MBSA BUILDING CONTRACTS'){
                return (
                    <React.Fragment key={index}>
                        <a className="mb_button" href={item.download_pdf ? `${BASE_URL}${item.download_pdf}` : '/'} target="_blank" title="MBSA PRICELISTS" el="noopener noreferrer">{item.download_title}</a>
                    </React.Fragment>
                )
            }       
            return <React.Fragment key={index}></React.Fragment>    
        })
    }


    renderPageContent(){
        return(
            <Nav>
                <section className="mb_pricelist-heading-section text-center">
                    <Container>
                        <Row>
                            <Col>
                                <HeadingPrimary 
                                    text={this.props.pageData && this.props.pageData.data.headline}
                                />
                                <div className="mb_border-bottom-center"></div>
                                <p className="mb_paragraph-2">Click on the links below to access the 2022/2023 MBA Western Cape Contract Document Pricelists:</p>
                            </Col>
                        </Row>
                        <Row className="mb_pricelist-button-row">
                            <Col>
                                {this.props.pdfs && this.renderJbccButton()}
                                {this.props.pdfs && this.renderMbsaButton()}
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col>
                                <p className="mb_paragraph-7">To place your document order either visit our offices at Belmont Square, Belmont Road, Rondebosch or alternatively, please contact</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="mb_paragraph">Lulu Butshingi | <a href="mailto:lulu@mbawc.org.za?subject=MBAWC Enquiry" className="mb_email" title="Send us an e-mail">lulu@mbawc.org.za</a> or <a href="tel:021 6852625" className="mb_telephone" title="Call us">021 6852625</a><span className="clearfix"></span> Contractual queries relating to this suite of documents to be addressed to the Director at email <span className="clearfix"></span><a href="mailto:info@mbawc.org.za?subject=MBAWC Enquiry" className="mb_email" title="Send us an e-mail">info@mbawc.org.za</a></p>
                            </Col>
                        </Row>*/}
                    </Container>
                </section>
                {this.props.pageData && this.renderBody()}
                {/* <PriceListSection /> */}
                {/* <FreeContractSection /> */}
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pdfs: state.priceListsState.data,
        pageData: state.pageDetailsState['services']
    }
}

export default connect(mapStateToProps, {
    fetchPricelists,
    fetchPageDetails
})(ContractDocumentPricelist)