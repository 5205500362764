import {
    FETCH_NEWS_ARTICLE
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_NEWS_ARTICLE:
            return {
                ...state,
                [action.slug]: action.payload
            }
        default:
            return state
    }
}