import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class BenefitsSection extends React.Component {

    render(){
        return(
            <section className="mb_benefits-section">
                <Container>
                    <Row>
                         {this.props.children}
                    </Row>
                </Container>
            </section>
            
        )
    }    
}

export default BenefitsSection