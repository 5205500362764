import {
    FETCH_EVENTS_UPCOMING
} from '../actions/types'

export default (state = [], action) => {
    switch (action.type) {
        case FETCH_EVENTS_UPCOMING:
            return action.payload
        default:
            return state
    }
}