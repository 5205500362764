import React from 'react'
import {CopyWrightLinks} from './NavLinks'

class FooterMobileMenu extends React.Component {
    render(){
        return(
            <footer className="mb_footer-menu-mobile">
                <ul>
                    <CopyWrightLinks />
                </ul>
            </footer>
        )
    }
}

export default FooterMobileMenu