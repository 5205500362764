import {
    FETCH_PRICELIST
} from '../actions/types'

export default (state = [], action) => {
    switch (action.type) {
        case FETCH_PRICELIST:
            return action.payload
        default:
            return state
    }
}