import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//buttons
import {ButtonLinkGoldFill} from '../components/buttons'

class WeAreSorry extends React.Component{

    state = {
        link: ''
    }

    componentDidMount(){
        if (window.location.href.indexOf('contact') > -1) {
            this.setState({
                link: '/contact'
            });
        } else if (window.location.href.indexOf('apply-for-membership') > -1) {
            this.setState({
                link: '/apply-for-membership'
            });
        } else {
            this.setState({
                link: '/'
            });
        }
    }

    renderPageContent(){
        return(
            <Nav>
                <section className="mb_thank-you-section text-center">
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={12} >
                                <div className="mb_thank-you-content">
                                    <h1 className="mb_heading-1-2">We Are Sorry</h1>
                                    <div className="mb_border-bottom-center"></div>
                                    <p className="mb_paragraph-3">We are sorry something went wrong. Please try again later.</p>
                                    <ButtonLinkGoldFill
                                        text="Close"
                                        link={this.state.link}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>We Are Sorry Page | Master Builders and Allied Trades Association Western Cape</title>
                    <meta name="description" content="Master Builders and Allied Trades Association Western Cape" />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

export default WeAreSorry