import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import Search from '../components/search/Search'
import SearchCategory from '../components/search/SearchCategory'
import SearchAll from '../components/search/SearchAll'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import {connect} from 'react-redux'
import {fetchPageDetails} from '../actions'


import MembersAllSection from '../components/sections/MembersAllSection'
import MembersCompanySection from '../components/sections/MembersCompanySection'
import MembersCategorySection from '../components/sections/MembersCategorySection'


class Members extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('members')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderList(){ 
        if (window.location.href.indexOf('company') > -1) {
            return <MembersCompanySection />
        } else if (window.location.href.indexOf('category') > -1) {
            return <MembersCategorySection />
        } else {
            return <MembersAllSection />
        }
    }

    renderPageContent(){
        return(
            <React.Fragment>
                <Nav>
                    <HeadingSection 
                        headline={this.props.pageData && this.props.pageData.data.headline}
                        text={this.props.pageData && this.props.pageData.data.subheading}
                    />
                    {this.props.pageData && this.renderBody()}
                    <section className="mb_search-members-page-section mb_form">
                        <Container>
                            <Row>
                                <Search />
                                <SearchCategory />
                            </Row>
                            <SearchAll />
                        </Container>
                    </section>
                    
                    {this.renderList()}
                </Nav>
            </React.Fragment>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['members']
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails
})(Members)