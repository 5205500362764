import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux'
import {fetchPageDetails} from '../actions'

//pdfs
import pdf from '../assets/pdfs/Benefits-of-Membership.pdf'

class BenefitsOfMembership extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('benefits-of-membership')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <section className="mb_about-benefits-section">
                    <Container>
                        <Row>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">01</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">THE AUTOMATIC RIGHT TO AFFILIATED MEMBERSHIP OF MBSA</h4>
                                        <p class="mb_paragraph">The leading national representative body in the building and construction industry in South Africa.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">02</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">INSURANCES</h4>
                                        <p class="mb_paragraph">(These are obligatory on all contracts.) Free expert advice and guidance from our inhouse MBA insurance company specialising in the Building Industry.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">03</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">LABOUR RELATIONS</h4>
                                        <p class="mb_paragraph">The MBAWC keeps Members updated in terms of the latest developments in the Labour Relations Act and will advise you on how to operate fairly and strictly within the Act. Membership of the MBAWC gives you the opportunity to benefit from collective bargaining and representation in both the labour market and the industrial sector.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">04</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">OCCUPATIONAL HEALTH AND SAFETY</h4>
                                        <p class="mb_paragraph">Our team of OHS Advisors are happy to assist and advise you on any aspect relating to OHS. If you follow the steps recommended in the MBSA Safety Manual, you will avoid unnecessary legal actions, be able to receive a rebate on your insurance payments and have a contented and more productive workforce.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">05</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">A FREE MONTHLY COPY OF MBSA’s OFFICIAL JOURNAL, SA BUILDER</h4>
                                        <p class="mb_paragraph">Which will assist you in keeping in touch with the national and regional building scene and contractual matters.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">06</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">THE RIGHT TO CALL ON THE ASSOCIATION FOR ASSISTANCE AND ADVICE IN ALL MATTERS THAT MIGHT AFFECT YOUR BUSINESS</h4>
                                        <p class="mb_paragraph">Contractual and Legal; Labour Relations; Occupational Health and Safety; Training & Education, etc. It is a fact that smaller companies, often ignorant of their rights and in some cases of their obligations, frequently fall foul of their clients, the professions, and indeed their own labour force. Help is only a phone call away. Bring MBAWC onto your side and get a clear, objective view of matters and obtain advice on the correct procedures to follow.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">07</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">THE RIGHT TO RECEIVE MBA NEWSLETTERS, CIRCULARS, AND BULLETINS,</h4>
                                        <p class="mb_paragraph">which will keep you updated with changes in legislation, and give recommendations on how to tackle those difficulties which continually arise.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">08</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">DISPUTE RESOLUTION</h4>
                                        <p class="mb_paragraph">The right to request MBAWC to appoint an Adjudicator, Mediator or Arbitrator, should you find yourself in a dispute situation with your Client.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">09</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">SKILLS TRAINING</h4>
                                        <p class="mb_paragraph">Access to MBAWC preferred Training Providers at special rates for the most up to date training of employees. The opportunity to be involved in Group Apprentice programmes and assistance with your Workplace Skills Plans.</p>
                                        <p class="mb_paragraph-2">(Note: Available to member companies following 12 months purchase of MBA benefit stamps, inclusive of Skills Levy, through BIBC.)</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">10</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">CONTRACT DOCUMENTS</h4>
                                        <p class="mb_paragraph">Assistance with and access to Building Contract Documentation at a considerable savings in cost.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">11</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">MARKETING</h4>
                                        <p class="mb_paragraph">Marketing of Master Builder member companies through the annual Membership Directory as well as press releases during the year.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">12</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">CONFERENCE FUNCTION FACILITIES</h4>
                                        <p class="mb_paragraph">Members enjoy a 50% discount on the hire of the J & J Conferencing Venue/Seminar rooms at Belmont Square Conference Centre.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="mb_border-bottom"></div>
                                <h4 class="mb_heading-3-2 mb_about-benefits-sub-heading">In addition, once the prescribed probationary period has been completed, FULL membership of MBAWC entitles you to the following further benefits:</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">01</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">THE RIGHT TO USE THE MBA LOGO ON YOUR LETTERHEADS, NOTICE BOARDS, BUSINESS PREMISES AND COMPANY.</h4>
                                        <p class="mb_paragraph">MBAWC Membership is recognised by the general public as a symbol of reliability and good faith. It will, therefore, assist you in securing more work from clients.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">02</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">UNSATISFACTORY PERFORMANCE</h4>
                                        <p class="mb_paragraph">Membership provides the opportunity to any member of the public with whom you do business to contact the Association regarding unsatisfactory performance. The Association will then be obliged, in every instance, to investigate and attempt to resolve the matter in a fair and equitable manner, to the ultimate benefit of both parties.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">03</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">THE RIGHT TO ADVERTISE IN THE MBA MEMBERSHIP DIRECTORY</h4>
                                        <p class="mb_paragraph">which is distributed free of charge to all professionals and stakeholders in the Built Environment as well as to 5000 members of the public each year, helping to ensure that MBA Members are kept in the public eye.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div  className="mb_about-benefits">
                                    <div className="mb_about-benefits-order"><span className="mb_heading-2-1">04</span></div>
                                    <div  className="mb_about-benefits-content">
                                        <h4 class="mb_heading-3-2">NETWORKING</h4>
                                        <p class="mb_paragraph">On the more informal side, you will also benefit from mixing and networking with other mba members at our regular seminars, workshops & presentations, our sports days and our annual social get-togethers.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <a className="mb_button" href={pdf} target="_blank" title="DOWNLOAD PDF" el="noopener noreferrer">DOWNLOAD PDF</a>
                        </Row>
                    </Container>
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['benefits-of-membership']
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails
})(BenefitsOfMembership)