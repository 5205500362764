import React from 'react'
import {NavLinksWidescreen} from './NavLinks'

class WidescreenNav extends React.Component {
    render(){
        return(
            <nav className="mb_widescreen-nav">
                <ul className="mb_unordered-list">
                    <NavLinksWidescreen />
                </ul>
            </nav>
        )
    }
}

export default WidescreenNav