import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import ContactForm from '../components/forms/ContactForm'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import locationIcon from '../assets/img/icons/location-icon.svg'
import officeHoursIcon from '../assets/img/icons/office-hours.svg'
import telephoneIcon from '../assets/img/icons/telephone.svg'
import emailIcon from '../assets/img/icons/email.svg'
import {connect} from 'react-redux'
import {fetchPageDetails} from '../actions'

import complaints from '../assets/pdfs/MBAWC_Complaints-Form_September-2024.pdf'
import info9 from '../assets/img/iconmonstr-info-9.svg'

class ContactUs extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('contact')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <section className="mb_contact-page-section mb_form">
                    <Container>
                        <Row>
                            <Col>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.273031320238!2d18.471695015762304!3d-33.95982118063124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5d2796a039a3%3A0x303999c99ba84a7!2sMaster%20Builders%20Association!5e0!3m2!1sen!2sza!4v1602501323532!5m2!1sen!2sza" width="100%" height="350" frameborder="0" style={{border:'0'}} allowFullScreen="" aria-hidden="false" tabindex="0"></iframe>
                            </Col>
                        </Row>
                        <Row className="mb_contact-page-row">
                            <Col sm={12} md={4} lg={4} xl={4} >
                                <h4 className="mb_heading-3-1">
                                    CONTACT INFO
                                </h4>
                                <div class="mb_border-bottom-left"></div>
                                <ul className="in_ul-contact">
                                    <li>
                                        <img src={locationIcon} alt="Location - Master Builders and Allied Trades Association Western Cape" />
                                        <div>
                                            <h5 className="mb_heading-9">Address</h5>
                                            <a href="https://goo.gl/maps/iMWRxN1SNNVhkLP7A" target="_blank" title="Open Google Maps" rel="noopener noreferrer">
                                                <p className="mb_paragraph-3">Belmont Square, Belmont Road ,</p>
                                                <p className="mb_paragraph-3">Rondebosch, 7700</p>
                                                <p className="mb_paragraph-3">P.O. Box 382, Rondebosch, 7701</p>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={officeHoursIcon} alt="Office Hours - Master Builders and Allied Trades Association Western Cape" />
                                        <div>
                                            <h5 className="mb_heading-9">Office Hours</h5>
                                            <p className="mb_paragraph-3">Monday to Thursday: 08:30 – 16:30 (Document Sales)</p>
                                            <p className="mb_paragraph-3">Friday: 08:30 - 15:30</p>
                                            <p className="mb_paragraph-3">Saturday: Closed</p>
                                            <p className="mb_paragraph-3">Sunday: Closed</p>
                                            {/* <p className="mb_paragraph-3">Closed</p> */}
                                        </div>
                                    </li>
                                    <li>
                                        <img src={emailIcon} alt="Our Email - Master Builders and Allied Trades Association Western Cape" />
                                        <div>
                                            <h5 className="mb_heading-9">Email Address</h5>
                                            <a href="mailto:info@mbawc.org.za?subject=MBAWC Enquiry" className="mb_paragraph-3" title="Send us an e-mail">info@mbawc.org.za</a>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={telephoneIcon} alt="Telephone - Master Builders and Allied Trades Association Western Cape" />
                                        <div>
                                            <h5 className="mb_heading-9">Telephone</h5>
                                            <a href="tel:021 685 2625" className="mb_paragraph-3" title="Call us">021 685 2625</a>
                                        </div>    
                                    </li>
                                    <li>
                                        <img src={info9} alt="Complaints" />
                                        <div>
                                            <h5 className="mb_heading-9">Complaints</h5>
                                            Click on the download link below to access our Member Complaints, Dispute and Grievance Form.<br />
                                            <a href={complaints} download="MBAWC Complaints Enquiry Form" className="mb_paragraph-3" title="Complaints">Download</a>
                                        </div>    
                                    </li>
                                </ul>
                            </Col>

                            <Col sm={12} md={8} lg={8} xl={8} >
                                <h4 className="mb_heading-3-1">
                                    NEED ASSISTANCE
                                </h4>
                                <div class="mb_border-bottom-left"></div>
                                <ContactForm />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['contact']
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails
})(ContactUs)