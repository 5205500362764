import {
    FETCH_FAQS
} from '../actions/types'

export default (state = [], action) => {
    switch (action.type) {
        case FETCH_FAQS:
            return action.payload
        default:
            return state
    }
}