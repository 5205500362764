import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {HeadingSecondary} from '../headings'
import Table from 'react-bootstrap/Table'

class FreeContractSection extends React.Component {

    render(){
        return(
            <section className="mb_free-contract-table-section" style={{backgroundColor: '#F5F5F5'}}>
                <Container>
                    <Row>
                        <Col>
                            <HeadingSecondary 
                                text="FREE CONTRACT DOCUMENT"
                            />
                            <div className="mb_border-bottom-center"></div>
                            <p className="mb_paragraph-2"></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 class="mb_heading-3-1">JBCC DOCUMENTS</h3>
                            <h4 class="mb_heading-9">FREE DOCUMENTS - The following documents are available for free at <a href="www.jbcc.co.za" target="_blank" title="FREE DOCUMENTS" el="noopener noreferrer">www.jbcc.co.za</a></h4>
                            <Table responsive="md" className="mb_table">
                                <tr>
                                    <th className="mb_table-col-2 mb_table-col-padding-right">
                                        CODE
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                    <th className="mb_table-col-8 mb_table-col-padding">
                                        DOCUMENT
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                    <th className="mb_table-col-2 mb_table-col-padding-left">
                                        WEBSITE
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height">
                                        N/A
                                    </td>
                                    <td className="mb_table-col-8 mb_table-col-padding mb_td-height">
                                        Site possession certificate 2013 Ed 6.0
                                    </td>
                                    <td className="mb_table-col-2 mb_table-col-padding-left mb_td-height">
                                        <a href="http://www.jbcc.co.za" target="_blank" title="JBCC DOCUMENTS" el="noopener noreferrer">Visit</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height">
                                        N/A
                                    </td>
                                    <td className="mb_table-col-8 mb_table-col-padding mb_td-height">
                                        Waiver of lien 2013 Ed 6.0
                                    </td>
                                    <td className="mb_table-col-2 mb_table-col-padding-left mb_td-height">
                                        <a href="http://www.jbcc.co.za" target="_blank" title="JBCC DOCUMENTS" el="noopener noreferrer">Visit</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height">
                                        N/A
                                    </td>
                                    <td className="mb_table-col-8 mb_table-col-padding mb_td-height">
                                        Payment Certificate PBA 2013 Ed 6.0
                                    </td>
                                    <td className="mb_table-col-2 mb_table-col-padding-left mb_td-height">
                                        <a href="http://www.jbcc.co.za" target="_blank" title="JBCC DOCUMENTS" el="noopener noreferrer">Visit</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height">
                                        N/A
                                    </td>
                                    <td className="mb_table-col-8 mb_table-col-padding mb_td-height">
                                        Payment Certificate Notification PBA 2013 Ed 6.0
                                    </td>
                                    <td className="mb_table-col-2 mb_table-col-padding-left mb_td-height">
                                        <a href="http://www.jbcc.co.za" target="_blank" title="JBCC DOCUMENTS" el="noopener noreferrer">Visit</a>
                                    </td>
                                </tr>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4 class="mb_heading-9">The following documents are available for free at <a href="www.jbcc.co.za" target="_blank" title="FREE DOCUMENTS" el="noopener noreferrer">www.jbcc.co.za</a></h4>
                            <Table responsive="md" className="mb_table">
                                <tr>
                                    <th className="mb_table-col-2 mb_table-col-padding-right">
                                        CODE
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                    <th className="mb_table-col-8 mb_table-col-padding">
                                        DOCUMENT
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                    <th className="mb_table-col-2 mb_table-col-padding-left">
                                        WEBSITE
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height">
                                        N/A
                                    </td>
                                    <td className="mb_table-col-9 mb_table-col-padding mb_td-height">
                                        Certificate of Completion PBA 2007-08
                                    </td>
                                    <td className="mb_table-col-2 mb_table-col-padding-left mb_td-height">
                                        <a href="http://www.jbcc.co.za" target="_blank" title="JBCC DOCUMENTS" el="noopener noreferrer">Visit</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height">
                                        N/A
                                    </td>
                                    <td className="mb_table-col-9 mb_table-col-padding mb_td-height">
                                        Certificate of Completion MWA 2007-08
                                    </td>
                                    <td className="mb_table-col-2 mb_table-col-padding-left mb_td-height">
                                        <a href="http://www.jbcc.co.za" target="_blank" title="JBCC DOCUMENTS" el="noopener noreferrer">Visit</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height">
                                        N/A
                                    </td>
                                    <td className="mb_table-col-9 mb_table-col-padding mb_td-height">
                                        Construction Guarantee PBA 2007 Ed 5.0
                                    </td>
                                    <td className="mb_table-col-2 mb_table-col-padding-left mb_td-height">
                                        <a href="http://www.jbcc.co.za" target="_blank" title="JBCC DOCUMENTS" el="noopener noreferrer">Visit</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height">
                                        N/A
                                    </td>
                                    <td className="mb_table-col-9 mb_table-col-padding mb_td-height">
                                        Variable Construction Guarantee MWA 2007 Ed 4.0
                                    </td>
                                    <td className="mb_table-col-2 mb_table-col-padding-left mb_td-height">
                                        <a href="http://www.jbcc.co.za" target="_blank" title="JBCC DOCUMENTS" el="noopener noreferrer">Visit</a>
                                    </td>
                                </tr>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4 class="mb_heading-9">JBCC is no longer reprinting the Preliminaries, it is available on their Website under “FREE FORM PRINTING”.</h4>
                        </Col>
                    </Row>
                </Container>
            </section>
            
        )
    }    
}

export default FreeContractSection