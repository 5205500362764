import React from 'react'
import Container from 'react-bootstrap/Container';
import {BASE_URL} from '../../path'

class BackgroundImageItem extends React.Component {
    render(){

        const { image, description } = this.props.data

        return(
            <div className="mb_background-image-item" 
                title='Master Builders and Allied Trades Association Western Cape'
                aria-label='Master Builders and Allied Trades Association Western Cape'
                style={{
                    backgroundImage: `url(${BASE_URL}${image}`
                }}
            >
                <Container>
                    <div className="mb_inner">
                        <h2 className="mb_heading-1">{description}</h2>
                    </div>
                </Container>
            </div>
        )
    }
}

export default BackgroundImageItem