import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NewsItem from '../items/NewsItem'
import {connect} from 'react-redux'
import {fetchNews} from '../../actions'
import {Pagination} from 'react-laravel-paginex'

class NewsListSection extends React.Component{

    componentDidMount(){
        if(!this.props.news){
            this.props.fetchNews(null)
        }
    }

    renderList(){
        return this.props.news.data.map((item, index) => {
            if(item.headline){
                return (
                    <React.Fragment>
                    <Col sm={12} md={6} lg={4} xl={4} >
                        <NewsItem
                            date={item.published}
                            text={item.headline}
                            link={`/news/${item.slug}`}
                        />
                    </Col>
                    </React.Fragment>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    render(){
        return(
            <section className="mb_news-list-section">
                <Container>
                    <Row>
                        <Col className="mb_search-results">
                            <h4 class="mb_paragraph">{`Found ${this.props.news && this.props.news.meta.total} news articles: Page ${this.props.news && this.props.news.meta.current_page} of ${this.props.news && this.props.news.meta.last_page}`}</h4>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        {this.props.news && this.renderList()}
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Pagination changePage={this.props.fetchNews} data={this.props.news} containerClass="mb_pagination" />
                    </Row>
                </Container>
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        news: state.newsState.data
    }
}

export default connect(mapStateToProps, {
    fetchNews
})(NewsListSection)