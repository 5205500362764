import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import {connect} from 'react-redux'
import {fetchPricelists} from '../../actions'

class PriceListSection extends React.Component {  

    componentDidMount(){
        if(!this.props.price){
            this.props.fetchPricelists(null)
        }
    }

    renderJbccPriceList(prices){
        return prices.map((item, index) => {
            return(
                <React.Fragment key={index}>
                    <tr>
                        <td className="mb_table-col-4 mb_table-col-padding-right">
                            {item.title}
                        </td>
                        <td className="mb_table-col-2 mb_table-col-padding-right">
                            {item.code}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {`R${item.members_excl_vat}`}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                           {`R${item.members_vat}`}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {`R${item.members_total}`}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {`R${item.non_members_excl_vat}`}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {`R${item.non_members_vat}`}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {`R${item.non_members_total}`}
                        </td>
                    </tr>
                </React.Fragment>  
            )
        })
    }

    renderJbccTable(){
        return this.props.price.data.map((item, index) => {
            if(item.description === 'JBCC CONTRACT DOCUMENTS'){
                if(item.prices) {
                    return this.renderJbccPriceList(item.prices)
                } else {
                    return <React.Fragment></React.Fragment>
                }
            }       
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderJbccHeading(){
        return this.props.price.data.map((item, index) => {
            if(item.description === 'JBCC CONTRACT DOCUMENTS'){
                return (
                    <React.Fragment key={index}>
                        <h3 class="mb_heading-3-1">{item.title}</h3>
                        <h4 class="mb_heading-9">{item.subtitle}</h4>
                    </React.Fragment>
                )
            }       
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderMbsaPriceList(prices){
        return prices.map((item, index) => {
            return(
                <React.Fragment key={index}>
                    <tr>
                        <th className="mb_table-col-4 mb_table-col-padding-right">
                            {item.title}
                            <span className="clearfix"></span>
                            <span className="mb_paragraph-2">{item.description}</span>
                        </th>
                        <th className="mb_table-col-2 mb_table-col-padding-right">
                           {item.code}
                        </th>
                        <td className="mb_table-col-1 mb_td-price">
                            {`R${item.members_excl_vat}`}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                           {`R${item.members_vat}`}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {`R${item.members_total}`}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {`R${item.non_members_excl_vat}`}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {`R${item.non_members_vat}`}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {`R${item.non_members_total}`}
                        </td>
                    </tr>
                </React.Fragment>  
            )
        })
    }

    renderMbsaTable(){
        return this.props.price.data.map((item, index) => {
            if(item.description === 'MBSA BUILDING CONTRACTS'){
                if(item.prices) {
                    return this.renderMbsaPriceList(item.prices)
                } else {
                    return <React.Fragment></React.Fragment>
                }
            }       
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderMbsaHeading(){
        return this.props.price.data.map((item, index) => {
            if(item.description === 'MBSA BUILDING CONTRACTS'){
                return (
                    <React.Fragment key={index}>
                        <h3 class="mb_heading-3-1">{item.title}</h3>
                        <h4 class="mb_heading-9">{item.subtitle}</h4>
                    </React.Fragment>
                )
            }       
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderSmallPriceList(prices){
        return prices.map((item, index) => {
            return(
                <React.Fragment key={index}>
                    <tr>
                        <th className="mb_table-col-4 mb_table-col-padding-right">
                            {item.title}
                            <span className="clearfix"></span>
                            <span className="mb_paragraph-2">{item.description}</span>
                        </th>
                        <th className="mb_table-col-2 mb_table-col-padding-right">
                           {item.code}
                        </th>
                        <td className="mb_table-col-1 mb_td-price">
                            {item.members_excl_vat}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                           {item.members_vat}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {item.members_total}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {item.non_members_excl_vat}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {item.non_members_vat}
                        </td>
                        <td className="mb_table-col-1 mb_td-price">
                            {item.non_members_total}
                        </td>
                    </tr>
                </React.Fragment>  
            )
        })
    }

    renderSmallTable(){
        return this.props.price.data.map((item, index) => {
            if(item.description === 'SMALL BUILDERS'){
                if(item.prices) {
                    return this.renderMbsaPriceList(item.prices)
                } else {
                    return <React.Fragment></React.Fragment>
                }
            }       
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    render(){
        return(
            <section className="mb_pricelist-table-section">
                <Container>
                    <Row>
                        <Col>
                            {this.props.price && this.renderJbccHeading()}
                            <Table responsive="md" className="mb_table">
                                <tr>
                                    <th colSpan="2" className="mb_table-col-6 mb_table-col-padding-right">
                                        JBCC CONTRACT DOCUMENTS
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                    <th colSpan="3" className="mb_table-col-3 mb_table-col-padding">
                                        MEMBERS
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                    <th colSpan="3" className="mb_table-col-3 mb_table-col-padding-left">
                                        NON-MEMBERS
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <td className="mb_table-col-4 mb_table-col-padding-right mb_td-height"></td>
                                    <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height"></td>
                                    <th className="mb_table-col-1 mb_th-gold">Excl. VAT</th>
                                    <th className="mb_table-col-1 mb_th-gold">VAT</th>
                                    <th className="mb_table-col-1 mb_th-gold">Total Price</th>
                                    <th className="mb_table-col-1 mb_th-gold">Excl. VAT</th>
                                    <th className="mb_table-col-1 mb_th-gold">VAT</th>
                                    <th className="mb_table-col-1 mb_th-gold">Total Price</th>
                                </tr>
                                {this.props.price && this.renderJbccTable()}
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.props.price && this.renderMbsaHeading()}
                            <Table responsive="md" className="mb_table mb_margin-bottom-20">
                                <tr>
                                    <th colSpan="2" className="mb_table-col-6 mb_table-col-padding-right">
                                         MBSA BUILDING CONTRACTS
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                    <th colSpan="3" className="mb_table-col-3 mb_table-col-padding">
                                        MEMBERS
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                    <th colSpan="3" className="mb_table-col-3 mb_table-col-padding-left">
                                        NON-MEMBERS
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <td className="mb_table-col-4 mb_table-col-padding-right mb_td-height"></td>
                                    <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height"></td>
                                    <th className="mb_table-col-1 mb_th-gold">Excl. VAT</th>
                                    <th className="mb_table-col-1 mb_th-gold">VAT</th>
                                    <th className="mb_table-col-1 mb_th-gold">Total Price</th>
                                    <th className="mb_table-col-1 mb_th-gold">Excl. VAT</th>
                                    <th className="mb_table-col-1 mb_th-gold">VAT</th>
                                    <th className="mb_table-col-1 mb_th-gold">Total Price</th>
                                </tr>
                                {this.props.price && this.renderMbsaTable()}
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table responsive="md" className="mb_table">
                                <tr>
                                    <th colSpan="2" className="mb_table-col-6 mb_table-col-padding-right">
                                          SMALL BUILDERS
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                    <th colSpan="3" className="mb_table-col-3 mb_table-col-padding">
                                        MEMBERS
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                    <th colSpan="3" className="mb_table-col-3 mb_table-col-padding-left">
                                        NON-MEMBERS
                                        <div className="mb_border-bottom-light"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <td className="mb_table-col-4 mb_table-col-padding-right mb_td-height"></td>
                                    <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height"></td>
                                    <th className="mb_table-col-1 mb_th-gold">Excl. VAT</th>
                                    <th className="mb_table-col-1 mb_th-gold">VAT</th>
                                    <th className="mb_table-col-1 mb_th-gold">Total Price</th>
                                    <th className="mb_table-col-1 mb_th-gold">Excl. VAT</th>
                                    <th className="mb_table-col-1 mb_th-gold">VAT</th>
                                    <th className="mb_table-col-1 mb_th-gold">Total Price</th>
                                </tr>
                                {this.props.price && this.renderSmallTable()}
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </section>
            
        )
    }    
}

const mapStateToProps = (state) => {
    return {
        price: state.priceListsState.data
    }
}

export default connect(mapStateToProps, {
    fetchPricelists
})(PriceListSection)