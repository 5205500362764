import React from 'react'
import Col from 'react-bootstrap/Col';

class TrainingSkillsItem extends React.Component {
    
    render(){
        const {image, title, subtitle, description, body, contact_person, email, pdf} = this.props
        return(
            <Col sm={12} md={6} lg={3} xl={3} >
                <div className="mb_training-skills-item mb_border">
                    <div className="mb_training-skills-item-content">
                        <div className="mb_training-skills-item-content-top">
                            <img src={image} alt={`${title}`} title={`${title}`} />
                            <h5 className="mb_heading-6-1">{title}</h5>
                            <h5 className="mb_heading-6-1">{subtitle}</h5>
                            <h6 className="mb_heading-6">{description}</h6>
                        </div>
                        <article dangerouslySetInnerHTML={{ __html: body }}></article>
                        <div className="mb_training-skills-item-content-bottom">
                            <p className="mb_paragraph-8">
                                {`Contact ${contact_person} at`}<br />
                                <a href={`mailto:${email}?subject=MBAWC Training Enquiry`} title="Send us an e-mail">{email}</a>
                            </p>
                            <a href={pdf} target="_blank" className="mb_paragraph-8" title={`${title}`} rel="noopener noreferrer">View Details ></a>
                        </div>
                    </div>
                </div>  
            </Col>
        )
    }
}

export default TrainingSkillsItem