import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import {connect} from 'react-redux'
import {fetchAssociations, fetchPageDetails} from '../actions'

function extractHostname(url) {
    let hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];

    } else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
}

function prependingUrl(url) {
    let link;

    if (!url.match(/^[a-zA-Z]+:\/\//)){
        link = 'http://' + url;
    } else {
        link = url;
    }

    return link;
}

class TelephoneNumbers extends React.Component{

    componentDidMount(){
        if(!this.props.contact){
            this.props.fetchAssociations(null)
        }
        this.props.fetchPageDetails('contacts')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderListAI(){
        return this.props.contact.data.map((item, index) => {
            if(item.category === 'Associations & Institutions'){
                return(
                    <React.Fragment key={index}>
                        <tr>
                            <td className="mb_table-col-6 mb_table-col-padding-right mb_td-height">
                                <a href={prependingUrl(item.website)} className="mb_telephone-number" target="_blank" title={item.name} el="noopener noreferrer">{item.name}</a>
                            </td>
                            <td className="mb_table-col-3 mb_table-col-padding mb_td-height">
                                <a href={`tel:${item.contact}`} className="mb_paragraph mb_telephone-number" title="Call us">{item.contact}</a>
                            </td>
                            <td className="mb_table-col-3 mb_table-col-padding-left mb_td-height">
                                <a href={prependingUrl(item.website)} target="_blank" title={item.name} el="noopener noreferrer">{extractHostname(item.website)}</a>
                            </td>
                        </tr>
                    </React.Fragment>
                )
            }
            return <React.Fragment key={index}></React.Fragment>    
        })

    }

    renderListI(){
        return this.props.contact.data.map((item, index) => {
            if(item.category === 'MBA Members Who Can Perform Inspections'){
                return(
                    <React.Fragment key={index}>
                        <tr>
                            <td className="mb_table-col-4 mb_table-col-padding-right mb_td-height">
                                {item.name}
                            </td>
                            <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height">
                                {item.contact_name}
                            </td>
                            <td className="mb_table-col-4 mb_table-col-padding mb_td-height">
                               <a href={`mailto:${item.email}?subject=MBAWC Enquiry`} className="mb_paragraph-3" title="Send us an e-mail">{item.email}</a>
                            </td>
                            <td className="mb_table-col-2 mb_table-col-padding mb_td-height">
                                <a href={`tel:${item.contact}`} className="mb_paragraph mb_telephone-number" title="Call us">{item.contact}</a>
                            </td>
                        </tr>
                    </React.Fragment>
                )
            }
            return <React.Fragment key={index}></React.Fragment>    
        })

    }

    renderListAAM(){
        return this.props.contact.data.map((item, index) => {
            if(item.category === 'Adjudicators, Arbitrators & Mediators'){
                return(
                    <React.Fragment key={index}>
                        <tr>
                            <td className="mb_table-col-4 mb_table-col-padding-right mb_td-height">
                                {item.name}
                            </td>
                            <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height">
                                {item.contact_name}
                            </td>
                            <td className="mb_table-col-4 mb_table-col-padding mb_td-height">
                               <a href={`mailto:${item.email}?subject=MBAWC Enquiry`} className="mb_paragraph-3" title="Send us an e-mail">{item.email}</a>
                            </td>
                            <td className="mb_table-col-2 mb_table-col-padding mb_td-height">
                                <a href={`tel:${item.contact}`} className="mb_paragraph mb_telephone-number" title="Call us">{item.contact}</a>
                            </td>
                        </tr>
                    </React.Fragment>
                )
            }
            return <React.Fragment key={index}></React.Fragment>    
        })

    }

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <section className="mb_telephone-numbers-table-section">
                    <Container>
                        <Row>
                            <Col>
                                <h3 class="mb_heading-3-1">ASSOCIATIONS & INSTITUTIONS</h3>
                                <Table responsive="lg" className="mb_table">
                                    <tr>
                                        <th className="mb_table-col-6 mb_table-col-padding-right">
                                            ASSOCIATION NAME
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                        <th className="mb_table-col-3 mb_table-col-padding">
                                            CONTACT NUMBER
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                        <th className="mb_table-col-3 mb_table-col-padding-left">
                                            WEBSITE ADDRESS
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                    </tr>
                                    {this.props.contact && this.renderListAI()}
                                </Table>
                                <p>There are a number of our members who perform Inspections and/or assist with Adjudication/Arbitration/Mediation matters.</p>
                                <p>Should you require the services of one of these members, please email us at <a href="mailto:info@mbawc.org.za">info@mbawc.org.za</a> detailing your request or contact us at <a href="tel:0216852625">021 685 2625</a> for further information.</p><br /><br />
                            </Col>
                        </Row>
                       
                        <Row>
                            <Col>
                                <h3 class="mb_heading-3-1">MBA MEMBERS WHO CAN PERFORM INSPECTIONS</h3>
                                <Table responsive="lg" className="mb_table">
                                    <tr>
                                        <th className="mb_table-col-4 mb_table-col-padding-right">
                                            COMPANY
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                        <th className="mb_table-col-2 mb_table-col-padding-right">
                                            CONTACT NAME
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                        <th className="mb_table-col-4 mb_table-col-padding">
                                            EMAIL ADDRESS
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                        <th className="mb_table-col-2 mb_table-col-padding">
                                            CONTACT NUMBER
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                    </tr>
                                    {this.props.contact && this.renderListI()}
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h3 class="mb_heading-3-1">ARBITRATORS, MEDIATORS, ADJUDICATORS</h3>
                                <Table responsive="lg" className="mb_table">
                                    <tr>
                                        <th className="mb_table-col-4 mb_table-col-padding-right">
                                            COMPANY
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                        <th className="mb_table-col-2 mb_table-col-padding-right">
                                            CONTACT NAME
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                        <th className="mb_table-col-4 mb_table-col-padding">
                                            EMAIL ADDRESS
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                        <th className="mb_table-col-2 mb_table-col-padding">
                                            CONTACT NUMBER
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                    </tr>
                                    {this.props.contact && this.renderListAAM()}
                                </Table>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        contact: state.associationsState.data,
        pageData: state.pageDetailsState['contacts']
    }
}

export default connect(mapStateToProps, {
    fetchAssociations,
    fetchPageDetails
})(TelephoneNumbers)