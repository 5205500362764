import React from 'react'

export class NextArrow extends React.Component {

    render(){
        const { onClick } = this.props;
        return(
            <div className="mb_next-arrow" onClick={onClick}></div>
        )
    }    
}

export class PrevArrow extends React.Component {

    render(){
        const { onClick } = this.props;
        return(
            <div className="mb_prev-arrow" onClick={onClick}></div>
        )
    }    
}

