import {
    FETCH_COMPANY_NAME
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_COMPANY_NAME:
            return action.payload
        default:
            return state
    }
}