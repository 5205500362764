import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
import {connect} from 'react-redux'
import {fetchMember, fetchMemberProject, fetchMemberProjectGallery} from '../actions'
import {BASE_URL} from '../path'
import ImageLightbox from '../components/ImageLightbox'
import {Link} from 'react-router-dom'

class MemberShowcaseProjectGallery extends React.Component{

    componentDidMount(){
        this.props.fetchMember(this.props.match.params.id)
        this.props.fetchMemberProject(this.props.match.params.id, this.props.match.params.project_id)
        this.props.fetchMemberProjectGallery(this.props.match.params.id, this.props.match.params.project_id)
    }

    renderItems(){
        return this.props.gallery.map(item => {
            return (
                <Col>
                    <figure>
                        <img src={BASE_URL+item.path} alt="" />
                    </figure>
                </Col>
            )
        })
    }

    renderPageContent(){
        return(
            <React.Fragment>
                <Nav>
                    <section className="mb_heading-section mb_showroom-heading-section text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    {
                                        this.props.member.logo && (
                                            <img className="_logo" src={BASE_URL+this.props.member.logo} alt="" />
                                        )
                                    }
                                    <Link to={`/members/showcase/${this.props.member.id}`}>
                                        <h1 className="mb_heading-1-2-2 text-center">{this.props.member.trading_name}</h1>
                                    </Link>
                                    <div className="mb_border-bottom-center" />
                                    <h3>{this.props.project.title}</h3>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="_member-showcase-project-gallery-section">
                        <Container>
                            {/* {this.props.gallery && this.renderItems()} */}
                            {this.props.gallery && <ImageLightbox images={this.props.gallery} />}
                        </Container>
                    </section>
                </Nav>
            </React.Fragment>
        )
    }

    render(){

        if(!this.props.member){
            return <></>
        }

        return(
            <React.Fragment>
                <Helmet>
                    <title>MBAWC - Member Showcase Projects - {this.props.member.trading_name}</title>
                </Helmet>
                {this.props.project && this.renderPageContent()}                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        member: state.memberState[ownProps.match.params.id],
        project: state.memberProjectState[ownProps.match.params.project_id],
        gallery: state.memberProjectGalleryState[ownProps.match.params.project_id]
    }
}

export default connect(mapStateToProps, {
    fetchMember,
    fetchMemberProject,
    fetchMemberProjectGallery
})(MemberShowcaseProjectGallery)