import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ImageItem from '../components/items/ImageItem'
import {connect} from 'react-redux'
import {fetchNewsArticle} from '../actions'
import {BASE_URL} from '../path'

//buttons
import {ButtonLink} from '../components/buttons'

class NewsArticle extends React.Component{

    componentDidMount(){
        this.props.fetchNewsArticle(this.props.match.params.slug)
    }

    renderBody(){
        if(this.props.news.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.news.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderPageContent(){
        return(
                <React.Fragment>
                    <Helmet>
                        <title>{this.props.news && this.props.news.data.title}</title>
                        <meta name="description" content={this.props.news && this.props.news.data.title} />
                        <meta name="keywords" content={this.props.news && this.props.news.data.title} />
                    </Helmet>
                    <Nav>
                    <HeadingSection 
                        headline={this.props.news.data.headline}
                        text={this.props.news.data.subheading}
                    />
                    {this.props.news && this.renderBody()}
                    <section className="mb_news-article-section">
                        <Container>
                            { this.props.news.data.image &&
                                <Row>
                                    <Col className="mb_image-center">
                                        <ImageItem 
                                            image={`${BASE_URL}${this.props.news.data.image}`}
                                            alt={this.props.news.data.headline}
                                        />
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col className="mb_center-text">
                                    <ButtonLink
                                        text="Back"
                                        link="/news"
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Nav>
            </React.Fragment>
        )
    }

    render(){
        return(
            <React.Fragment>
                {this.props.news && this.renderPageContent()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        news: state.newsArticleState[ownProps.match.params.slug]
    }
}

export default connect(mapStateToProps, {
    fetchNewsArticle
})(NewsArticle)