import React from 'react'
import {
    Link
} from 'react-router-dom'

class NewsItem extends React.Component {
    
    render(){
        const {date, text, link} = this.props
        return(
            <div className="mb_news-item">
                <article>
                    <span className="mb_paragraph-3">{date}</span>
                    <h4 className="mb_heading-3-1">{text}</h4>
                    <Link className="mb_read-more-link" to={link}>Read More</Link>
                </article>   
            </div>
        )
    }
}

export default NewsItem