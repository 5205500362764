import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import {connect} from 'react-redux'
import {fetchJointPracticeCommittee, fetchPageDetails} from '../actions'
import {BASE_URL} from '../path'

class JointPracticeCommitee extends React.Component{

    componentDidMount(){
        if(!this.props.notes){
            this.props.fetchJointPracticeCommittee(null)
        }
       this.props.fetchPageDetails('joint-practice-commitee')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderList(){
         return this.props.notes.data.map((item, index) => {
            if(item.practice_note_no){
                return (   
                    <React.Fragment key={index}>
                        <tr>
                            <td className="mb_table-col-2 mb_table-col-padding-right mb_td-height">
                                {item.practice_note_no}
                            </td>
                            <td className="mb_table-col-7 mb_table-col-padding mb_td-height">
                                {item.item}
                            </td>
                            <td className="mb_table-col-3 mb_table-col-padding-left mb_td-height">
                                <a href={item.pdf ? `${BASE_URL}${item.pdf}` : '/'} target="_blank" title="Download PDF" el="noopener noreferrer">Download PDF</a>
                            </td>
                        </tr>
                    </React.Fragment>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <section className="mb_joint-practice-commitee-section">
                    <Container>
                        {/*<Row>
                            <Col>
                                <ul>
                                    <li>CONSTITUENTS:</li>
                                    <li>ASSOCIATION OF SOUTH AFRICAN QUANTITY SURVEYORS</li>
                                    <li>CAPE INSTITUTE FOR ARCHITECTURE</li>
                                    <li>CHARTERED INSTITUTE OF BUILDING</li>
                                    <li>CONSTRUCTION INDUSTRY DEVELOPMENT BOARD</li>
                                    <li>CONSULTING ENGINEERS SOUTH AFRICA</li>
                                    <li>ELECTRICAL CONTRACTORS ASSOCIATION</li>
                                    <li>MASTER BUILDERS & ALLIED TRADES ASSOCIATION, W.CAPE</li>
                                    <li>SOUTH AFRICAN PROPERTY OWNERS’ ASSOCIATION</li>
                                </ul>

                                <div className="mb_border-bottom"></div>

                                <p className="mb_paragraph">The Joint Practice Committee (JPC) in the Western Cape is a joint body of representative members appointed by the above constituent bodies. The committee meets quarterly (or as the need arises) with the primary objective of promoting harmony between the constituent associations in their respective roles within the building industry and to discuss and investigate matters of contract or tender conditions on which differences exist or arise from time to time.</p>

                                <p className="mb_paragraph">The committee does not have the jurisdiction to be prescriptive. The aim of the committee is to be informative and provide guidelines, by way of Practice Notes, to promote best practice and fair competition in the industry.</p>

                                <p className="mb_paragraph">The Joint Practice Committee wishes to reiterate its firm belief that tender conditions should be such that they will promote a contractual climate beneficial to all stakeholders in the Building Industry.</p>

                                <p className="mb_paragraph">The committee welcomes through its constituent bodies any issues being brought to the table through its constituent representatives, which in the opinion of the members, do not subscribe to the aims and objectives of the committee.</p>

                                <p className="mb_paragraph">Yours faithfully <span className="clearfix"></span>
                                    For Joint Practice Committee, Western Cape</p>

                                <ul>
                                    <li>JPC Secretariat</li>
                                    <li>C/o MBA Western Cape</li>
                                    <li>Tel.<a href="tel:021 685 2625" className="mb_paragraph" title="Call us">021 685 2625</a></li>
                                    <li>Email - <a href="mailto:info@mbawc.org.za?subject=MBAWC Enquiry" className="mb_paragraph" title="Send us an e-mail">info@mbawc.org.za</a></li>
                                </ul>

                                <div className="mb_border-bottom"></div>

                            </Col>
                        </Row>*/}
                        <Row>
                            <Col>
                                <h3 class="mb_heading-3-1">INDEX TO JOINT PRACTICE COMMITTEE PRACTICE NOTES</h3>
                                <Table responsive="lg" className="mb_table">
                                    <tr>
                                        <th className="mb_table-col-2 mb_table-col-padding-right">
                                            PRACTICE NOTE NO.
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                        <th className="mb_table-col-7 mb_table-col-padding">
                                            ITEM
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                        <th className="mb_table-col-3 mb_table-col-padding-left">
                                            PDF
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                    </tr>
                                    {this.props.notes && this.renderList()}
                                </Table>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        notes: state.jointPracticeCommitteeState.data,
        pageData: state.pageDetailsState['joint-practice-commitee']
    }
}

export default connect(mapStateToProps, {
    fetchJointPracticeCommittee,
    fetchPageDetails
})(JointPracticeCommitee)