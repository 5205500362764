import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import ImageItem from '../components/items/ImageItem'
import {connect} from 'react-redux'
import {fetchStaff, fetchPageDetails} from '../actions'

//images
import image from '../assets/img/about-staff@2x.jpg'

class Staff extends React.Component{

    componentDidMount(){
        if(!this.props.staff){
            this.props.fetchStaff(null)
        }
        this.props.fetchPageDetails('staff')
    }

     renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderList(){
        return this.props.staff.data.map((item, index) => {
            if(item.position){
                return(
                    <React.Fragment key={index}>
                        <tr>
                            <td>{item.position}</td>
                            <td>{item.name}</td>
                            <td><a href={`mailto:${item.email}?subject=MBAWC Enquiry`} className="mb_paragraph-3" title="Send us an e-mail">{item.email}</a></td>
                        </tr>
                    </React.Fragment>
                )
            }
            return <React.Fragment key={index}></React.Fragment>    
        })

    }

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <section className="mb_staff-section">
                    <Container>
                        <Row>
                            <Col>
                                <table>
                                    <tr>
                                        <th>
                                            TITLE
                                            <div className="mb_border-bottom-theme-color"></div>
                                        </th>
                                        <th>
                                            NAME
                                            <div className="mb_border-bottom-theme-color"></div>
                                        </th>
                                        <th>
                                            E-MAIL ADDRESS
                                            <div className="mb_border-bottom-theme-color"></div>
                                        </th>
                                    </tr>
                                    {this.props.staff && this.renderList()}
                                </table>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mb_image-center">
                                <ImageItem 
                                    image={image}
                                    alt="Master Builders and Allied Trades Association Western Cape Staff"
                                />
                            </Col>
                        </Row>
                        
                    </Container>
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        staff: state.staffState.data,
        pageData: state.pageDetailsState['staff']
    }
}

export default connect(mapStateToProps, {
    fetchStaff,
    fetchPageDetails
})(Staff)