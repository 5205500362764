import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux'
import {fetchMembersCategories, fetchMembersCategory} from '../../actions'
import {Pagination} from 'react-laravel-paginex'

//items
import MembersItem from '../items/MembersItem'

const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('category');

class MembersCategorySection extends React.Component{

    componentDidMount(){
        if(!this.props.categories){
            this.props.fetchMembersCategories(null)
        }
        this.props.fetchMembersCategory(myParam)
    }

    renderList(){
        return this.props.category.data.map((item, index) => {
            if(item.trading_name){
                return ( 
                    <React.Fragment key={index}>  
                        <MembersItem
                            id={item.id}
                            logo={item.logo}
                            trading_name={item.trading_name}
                            member_since={item.member_since}
                            certificate={item.certificate}
                            company_contact_name={item.company_contact_name} 
                            company_contact_surname={item.company_contact_surname}  
                            company_landline_tel={item.company_landline_tel}  
                            company_cellphone_number={item.company_cellphone_number}  
                            company_street_address={item.company_street_address}  
                            email={item.email}  
                            website={item.website}  
                            categories={item.categories}
                            member_type={item.member_type}  
                            company_postal_address={item.company_postal_address}  
                            city={item.city}  
                            company_postal_address_postal_code={item.company_postal_address_postal_code} 
                            projects={item.projects}
                        />
                    </React.Fragment>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>  
        })
    }

    renderCategoryName(){
         return this.props.categories.data.map((item, index) => {
            if(myParam === item.search_name){
                return (   
                    <React.Fragment key={index}>
                        {item.title}
                    </React.Fragment>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    render(){
        return(
            <React.Fragment>
                <section className="mb_search-members-table-section">
                    <Container>
                        <Row>
                            <Col>
                                <h4 class="mb_paragraph">Your search for <span class="mb_heading-9">{this.props.categories && this.renderCategoryName()}</span> found <span class="mb_heading-9">{this.props.category && this.renderList().length}</span> results.</h4>
                            {/*<h4 class="mb_paragraph">Your search for <span class="mb_heading-9">{myParam}</span> found {`${this.props.category && this.props.category.meta.total} results: Page ${this.props.category && this.props.category.meta.current_page} of ${this.props.category && this.props.category.meta.last_page}`}</h4>*/}
                                {this.props.category && this.renderList()}
                            </Col>
                        </Row>
                        <Row>
                            {/*<Pagination changePage={this.props.membersCategoryState} data={this.props.category} containerClass="mb_pagination" />*/}
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        categories: state.membersCategoriesState.data,
        category: state.membersCategoryState[myParam],
    }
}

export default connect(mapStateToProps, {
    fetchMembersCategory,
    fetchMembersCategories
})(MembersCategorySection)