import mbawc from '../api'
import {
    FETCH_NEWS,
    FETCH_NEWS_DATE,
    FETCH_NEWS_HOME,
    FETCH_NEWS_ARTICLE,
    FETCH_EVENTS,
    FETCH_EVENTS_PAST,
    FETCH_EVENTS_UPCOMING,
    FETCH_EVENTS_YEAR,
    FETCH_EVENT,
    FETCH_FAQS,
    FETCH_MEMBERS,
    FETCH_MEMBERS_COMPANY,
    FETCH_MEMBERS_CATEGORY,
    FETCH_MEMBERS_CATEGORIES,
    FETCH_COMPANY_NAME,
    FETCH_MANAGEMENT,
    FETCH_STAFF,
    FETCH_PRICELIST,
    FETCH_SOCIAL_RESPONSIBILITY,
    FETCH_PARTNERS,
    FETCH_HEALTH_SAFETY,
    FETCH_ASSOCIATIONS,
    FETCH_JOINT_PRACTICE_COMMITTEE,
    FETCH_PAGES,
    FETCH_PAGE_DETAILS,
    FETCH_OPTIONS,
    FETCH_OPTION_DETAILS,
    FETCH_COURSES,
    FETCH_COURSES_HOME,
    FETCH_HOME_SLIDES,
    FETCH_MEMBER,
    FETCH_MEMBER_PROJECT,
    FETCH_MEMBER_PROJECTS,
    FETCH_MEMBER_PROJECT_GALLERY,
    FETCH_EMPLOYMENT_OPPORTUNITIES
} from './types'

// import history from '../history'

export const fetchNews = data => async dispatch => {
    const response = await mbawc.get(`/news${data ? '?page=' + data.page : ''}`)
    dispatch ({
        type: FETCH_NEWS,
        payload: {
            data: response.data
        }
    })
}

export const fetchNewsDate = (year, month, data) => async dispatch => {
    const response = await mbawc.get(`/news/${year}/${month}${data ? '?page=' + data.page : ''}`)
    dispatch ({
        type: FETCH_NEWS_DATE,
        payload: {
            data: response.data,
            year,
            month
        }
    })
}

export const fetchNewsHome = data => async dispatch => {
    const response = await mbawc.get('/news/limit/6')
    dispatch ({
        type: FETCH_NEWS_HOME,
        payload: {
            data: response.data
        }
    })
}

export const fetchNewsArticle = slug => async dispatch => {
    const response = await mbawc.get(`news/${slug}`)

    dispatch ({
        type: FETCH_NEWS_ARTICLE,
        payload: response.data,
        slug
    })
}

export const fetchEvents = data => async dispatch => {
    const response = await mbawc.get(`/events${data ? '?page=' + data.page : ''}`)
    dispatch ({
        type: FETCH_EVENTS,
        payload: {
            data: response.data
        }
    })
}

export const fetchEventsPast = data => async dispatch => {
    const response = await mbawc.get(`/events/past${data ? '?page=' + data.page : ''}`)
    dispatch ({
        type: FETCH_EVENTS_PAST,
        payload: {
            data: response.data
        }
    })
}

export const fetchEventsUpcoming = data => async dispatch => {
    const response = await mbawc.get(`/events/upcoming${data ? '?page=' + data.page : ''}`)
    dispatch ({
        type: FETCH_EVENTS_UPCOMING,
        payload: {
            data: response.data
        }
    })
}

export const fetchEventsYear = (year, data) => async dispatch => {
    const response = await mbawc.get(`/events/year/${year}${data ? '?page=' + data.page : ''}`)
    dispatch ({
        type: FETCH_EVENTS_YEAR,
        payload: {
            data: response.data,
            year
        }
    })
}

export const fetchEvent = slug => async dispatch => {
    const response = await mbawc.get(`events/${slug}`)

    dispatch ({
        type: FETCH_EVENT,
        payload: response.data,
        slug
    })
}

export const fetchFaqs = data => async dispatch => {
    const response = await mbawc.get(`/faqs${data ? '?page=' + data.page : ''}`)
    dispatch ({
        type: FETCH_FAQS,
        payload: {
            data: response.data
        }
    })
}

export const fetchMembers = data => async dispatch => {
    const response = await mbawc.get(`/members${data ? '?page=' + data.page : ''}`)
    dispatch ({
        type: FETCH_MEMBERS,
        payload: {
            data: response.data
        }
    })
}

export const fetchMembersCompany = (keywords, data) => async dispatch => {
    const response = await mbawc.get(`/members/company/${keywords}${data ? '?page=' + data.page : ''}`)
    dispatch ({
        type: FETCH_MEMBERS_COMPANY,
        payload: {
            data: response.data,
            keywords
        }
    })
}

export const fetchMembersCategory = (search_name) => async dispatch => {
    const response = await mbawc.get(`/members/category/${search_name}`)
    dispatch ({
        type: FETCH_MEMBERS_CATEGORY,
        payload: {
            data: response.data,
            search_name
        }
    })
}

export const fetchMembersCategories = data => async dispatch => {
    const response = await mbawc.get('/members/categories')
    dispatch ({
        type: FETCH_MEMBERS_CATEGORIES,
        payload: {
            data: response.data
        }
    })
}

export const fetchCompanyName = data => async dispatch => {
    const response = await mbawc.get('/members/company-name')
    dispatch ({
        type: FETCH_COMPANY_NAME,
        payload: {
            data: response.data
        }
    })
}

export const fetchManagement = data => async dispatch => {
    const response = await mbawc.get('/management')
    dispatch ({
        type: FETCH_MANAGEMENT,
        payload: response.data
    })
}

export const fetchStaff = data => async dispatch => {
    const response = await mbawc.get('/staff')
    dispatch ({
        type: FETCH_STAFF,
        payload: {
            data: response.data
        }
    })
}

export const fetchSocialResponsibility = data => async dispatch => {
    const response = await mbawc.get(`/corporate-and-social-responsibility`)
    dispatch ({
        type: FETCH_SOCIAL_RESPONSIBILITY,
        payload: {
            data: response.data
        }
    })
}

export const fetchPartners = data => async dispatch => {
    const response = await mbawc.get(`/corporate-partners`)
    dispatch ({
        type: FETCH_PARTNERS,
        payload: {
            data: response.data
        }
    })
}

export const fetchPricelists = data => async dispatch => {
    const response = await mbawc.get(`/pricelists${data ? '?page=' + data.page : ''}`)
    dispatch ({
        type: FETCH_PRICELIST,
        payload: {
            data: response.data
        }
    })
}

export const fetchHealthSafety = data => async dispatch => {
    const response = await mbawc.get(`/occupational-health-and-safety${data ? '?page=' + data.page : ''}`)
    dispatch ({
        type: FETCH_HEALTH_SAFETY,
        payload: {
            data: response.data
        }
    })
}

export const fetchAssociations = data => async dispatch => {
    const response = await mbawc.get('/contacts')
    dispatch ({
        type: FETCH_ASSOCIATIONS,
        payload: {
            data: response.data
        }
    })
}

export const fetchJointPracticeCommittee = data => async dispatch => {
    const response = await mbawc.get('/joint-practice-committee-practice-notes')
    dispatch ({
        type: FETCH_JOINT_PRACTICE_COMMITTEE,
        payload: {
            data: response.data
        }
    })
}

export const fetchPages = data => async dispatch => {
    const response = await mbawc.get('/pages')
    dispatch ({
        type: FETCH_PAGES,
        payload: {
            data: response.data
        }
    })
}

export const fetchPageDetails = (searchName) => async dispatch => {
    const response = await mbawc.get(`/pages/${searchName}`)
    dispatch ({
        type: FETCH_PAGE_DETAILS,
        payload: {
            data: response.data,
            searchName
        }
    })
}

export const fetchOptions = data => async dispatch => {
    const response = await mbawc.get('/options')
    dispatch ({
        type: FETCH_OPTIONS,
        payload: {
            data: response.data
        }
    })
}

export const fetchOptionDetails = (searchName) => async dispatch => {
    const response = await mbawc.get(`/options/${searchName}`)
    dispatch ({
        type: FETCH_OPTION_DETAILS,
        payload: {
            data: response.data,
            searchName
        }
    })
}

export const fetchCourses = data => async dispatch => {
    const response = await mbawc.get(`/courses${data ? '?page=' + data.page : ''}`)
    dispatch ({
        type: FETCH_COURSES,
        payload: {
            data: response.data
        }
    })
}

export const fetchCoursesHome = data => async dispatch => {
    const response = await mbawc.get('/courses/limit/4')
    dispatch ({
        type: FETCH_COURSES_HOME,
        payload: {
            data: response.data
        }
    })
}

export const fetchHomeSlides = data => async dispatch => {
    const response = await mbawc.get('/homepage-slides')
    dispatch ({
        type: FETCH_HOME_SLIDES,
        payload: {
            data: response.data
        }
    })
}

export const fetchMember = id => async dispatch => {
    const response = await mbawc.get('/members/'+id)
    dispatch ({
        type: FETCH_MEMBER,
        payload: response.data.data
    })
}

export const fetchMemberProjects = id => async dispatch => {
    const response = await mbawc.get('/member/'+id+'/projects')
    dispatch ({
        type: FETCH_MEMBER_PROJECTS,
        payload: response.data,
        id
    })
}

export const fetchMemberProjectGallery = (id, project_id) => async dispatch => {
    const response = await mbawc.get('/member/'+id+'/projects/'+project_id+'/gallery')
    dispatch ({
        type: FETCH_MEMBER_PROJECT_GALLERY,
        payload: response.data,
        id: project_id
    })
}

export const fetchMemberProject = (id, project_id) => async dispatch => {
    const response = await mbawc.get('/member/'+id+'/projects/'+project_id)
    dispatch ({
        type: FETCH_MEMBER_PROJECT,
        payload: response.data,
        id: project_id
    })
}

export const fetchEmploymentOpportunities = () => async dispatch => {
    const response = await mbawc.get('/employment-opportunities')
    dispatch ({
        type: FETCH_EMPLOYMENT_OPPORTUNITIES,
        payload: response.data.data
    })
}