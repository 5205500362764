import React from 'react'
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux'
import {fetchMembersCategories} from '../../actions'

class SearchCategory extends React.Component{

    state = {
        category: ''
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleSubmit = event => {
        if(this.state.category !== ''){
            this.props.history.push(`/members${this.state.category}`)
        } else {
            this.props.history.push('/members')
        }
    };

    componentDidMount(){
        if(!this.props.categories){
            this.props.fetchMembersCategories(null)
        }
    }

    

    renderList(){
         return this.props.categories.data.map((item, index) => {
            if(item.title){
                return (   
                    <React.Fragment key={index}>
                        <option value={item.search_name}>{item.title}</option>
                    </React.Fragment>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    render(){
        return(                     
            <Col sm={12} lg={6}>
                <form className="mb_search-form" onSubmit={this.handleSubmit}>
                    <div className="mb_form-input">
                        <label className="mb_heading-9" for="category">Search by Category</label>
                        <select 
                            className="mb_form-control" 
                            name="category"
                            value={this.state.category}
                            onChange={this.handleChange}
                        >
                            <option value="">Select Category</option>
                            {this.props.categories && this.renderList()}
                        </select>
                    </div>
                    <input className="mb_button" type="submit" value="SEARCH" />
                </form>
            </Col>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        categories: state.membersCategoriesState.data
    }
}

export default connect(mapStateToProps, {
    fetchMembersCategories
})(SearchCategory)