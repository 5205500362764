import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ImageItem from '../components/items/ImageItem'
import {connect} from 'react-redux'
import {fetchEvent, fetchOptions} from '../actions'
import {BASE_URL} from '../path'

//buttons
import {ButtonLink} from '../components/buttons'

class Event extends React.Component{

    componentDidMount(){
        this.props.fetchEvent(this.props.match.params.slug)
        if(!this.props.disclaimer){
            this.props.fetchOptions(null)
        }
    }

    renderBody(){
        if(this.props.event.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.event.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderPageContent(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.event && this.props.event.data.title}</title>
                    <meta name="description" content={this.props.event && this.props.event.data.title} />
                    <meta name="keywords" content={this.props.event && this.props.event.data.title} />
                </Helmet>
                <Nav>
                    <HeadingSection 
                        headline={this.props.event.data.headline}
                        text={this.props.event.data.subheading}
                    />
                    <section className="mb_event-section-date">
                        <Container>
                            <Row>
                                <Col>
                                    <span>Start date: </span>{this.props.event.data.start_date}
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    {this.props.event && this.renderBody()}
                    {this.props.event.data.image &&
                        <section className="mb_event-section">
                            <Container>
                                <Row>
                                    <Col className="mb_image-center">
                                        <ImageItem 
                                            image={`${BASE_URL}${this.props.event.data.image}`}
                                            alt={this.props.event.data.headline}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    }
                    <section className="mb_event-table-section">
                        <Container>
                            {/*<Row>
                                <Col>
                                    <Table responsive="lg" className="mb_table">
                                        <tr>
                                            <th className="mb_table-col-3 mb_table-col-padding-right">
                                                DATE
                                                <div className="mb_border-bottom-theme-color"></div>
                                            </th>
                                            <th className="mb_table-col-3 mb_table-col-padding">
                                                MONTH
                                                <div className="mb_border-bottom-theme-color"></div>
                                            </th>
                                            <th className="mb_table-col-6 mb_table-col-padding-left">
                                                EVENT
                                                <div className="mb_border-bottom-theme-color"></div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="mb_table-col-3 mb_table-col-padding-right mb_td-height">
                                                26
                                            </td>
                                            <td className="mb_table-col-3 mb_table-col-padding mb_td-height">
                                                March
                                            </td>
                                            <td className="mb_table-col-6 mb_table-col-padding-left mb_td-height">
                                                MBAWC Annual Members’ Golf Day - <span className="mb_paragraph-7">POSTPONED</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="mb_table-col-3 mb_table-col-padding-right mb_td-height">
                                                17
                                            </td>
                                            <td className="mb_table-col-3 mb_table-col-padding mb_td-height">
                                                April
                                            </td>
                                            <td className="mb_table-col-6 mb_table-col-padding-left mb_td-height">
                                                MBAWC Bowls Day - <span className="mb_paragraph-7">POSTPONED</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="mb_table-col-3 mb_table-col-padding-right mb_td-height">
                                                15
                                            </td>
                                            <td className="mb_table-col-3 mb_table-col-padding mb_td-height">
                                                July
                                            </td>
                                            <td className="mb_table-col-6 mb_table-col-padding-left mb_td-height">
                                                Annual Members’ Dinner 2020 - <span className="mb_paragraph-7">POSTPONED</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="mb_table-col-3 mb_table-col-padding-right mb_td-height">
                                                6
                                            </td>
                                            <td className="mb_table-col-3 mb_table-col-padding mb_td-height">
                                                August
                                            </td>
                                            <td className="mb_table-col-6 mb_table-col-padding-left mb_td-height">
                                                MBAWC Women’s Day Event (TBC)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="mb_table-col-3 mb_table-col-padding-right mb_td-height">
                                                12
                                            </td>
                                            <td className="mb_table-col-3 mb_table-col-padding mb_td-height">
                                                August
                                            </td>
                                            <td className="mb_table-col-6 mb_table-col-padding-left mb_td-height">
                                                New Members’ Information Session
                                            </td>
                                        </tr>
                                    </Table>
                                </Col>
                            </Row>*/}
                            <Row>
                                <Col className="mb_center-text">
                                { this.props.disclaimer.events_footer_disclaimer &&
                                    <article dangerouslySetInnerHTML={{ __html: this.props.disclaimer.events_footer_disclaimer }}></article>
                                }
                                    <ButtonLink
                                        text="Back"
                                        link="/events"
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Nav>
            </React.Fragment>
        )
    }

    render(){
        return(
            <React.Fragment>
                {this.props.event && this.renderPageContent()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        event: state.eventState[ownProps.match.params.slug],
        disclaimer: state.optionsState.data
    }
}

export default connect(mapStateToProps, {
    fetchEvent,
    fetchOptions
})(Event)