import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import RoundImageItem from '../components/items/RoundImageItem'
import {connect} from 'react-redux'
import {fetchSocialResponsibility, fetchPageDetails} from '../actions'
import {BASE_URL} from '../path'

class CorporateSocialResponsibility extends React.Component{

    componentDidMount(){
        if(!this.props.responsibility){
            this.props.fetchSocialResponsibility(null)
        }
        this.props.fetchPageDetails('corporate-social-responsibility')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderList(){
        return this.props.responsibility.data.map((item, index) => {
            if(item.title){
                return (
                    <React.Fragment key={index}>
                        <RoundImageItem
                            image={`${BASE_URL}${item.image}`}
                            title={item.title}
                            link={item.website}
                        />
                    </React.Fragment>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <section className="mb_corporate-social-responsibily-section">
                    <Container>
                        <Row>
                            {this.props.responsibility && this.renderList()}
                        </Row>
                    </Container>
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        responsibility: state.responsibilityState.data,
        pageData: state.pageDetailsState['corporate-social-responsibility']
    }
}

export default connect(mapStateToProps, {
    fetchSocialResponsibility,
    fetchPageDetails
})(CorporateSocialResponsibility)