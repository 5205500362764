import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class TrainingSkillsSection extends React.Component{
    render(){

        return(
            <section className="mb_training-skills-section">
                <Container>
                    <Row>
                        {this.props.children}     
                    </Row>
                </Container>
            </section>
        )
    }
}

export default TrainingSkillsSection