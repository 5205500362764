import React from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class SearchAll extends React.Component {
    
    render(){
        return(
            <Row>
                <Col>
                    <div className="mb_search-all">
                        <p className="mb_heading-9">View all members <Link to="/members?search=all" className="mb_button-search-all">click here</Link></p>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default SearchAll