import React from 'react'
import history from '../../history'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class SearchNewsDate extends React.Component{

    constructor() {
        super();
        const thisYear = (new Date()).getFullYear();
        const thisMonth = (new Date()).getMonth() + 1;
        this.state = {
            month: '',
            year: '',
            thisYear: thisYear,
            thisMonth: thisMonth,
            months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ]
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleSubmit = event => {
        if(this.state.year !== ''){
            history.push(`/news?year=${this.state.year}&month=${this.state.month}`)
        } else {
            history.push('/news')
        }
    };

    renderMonths(){
        return this.state.months.map((item, index) => {
            if(item){
                return(
                    <React.Fragment >
                        <option value={index + 1} >{item}</option>
                    </React.Fragment>
                )
            }
            return <React.Fragment key={index}></React.Fragment>
        })
    }

    renderYears(){
        const {thisYear} = this.state;
        const startYear = 2015;
        const options = [];
        
        for (let i = startYear; i <= thisYear; i++) {
            const year = i;
            options.push(<option value={year}>{year}</option>);
        }
        return (
            <React.Fragment> 
                {options}
            </React.Fragment>
        );
    }

    render(){
        return(       
                
                <form className="mb_search-form-date-news" onSubmit={this.handleSubmit}>
                    <Row>
                        <Col xs={12} sm={6} lg={5}>
                            <div className="mb_form-input">
                                <label className="mb_heading-9" for="year">Select Year</label>
                                <select 
                                    className="mb_form-control" 
                                    name="year"
                                    value={this.state.year}
                                    onChange={this.handleChange}
                                >
                                    <option value="null" >Select Year</option>
                                    {this.renderYears()}
                                </select>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} lg={5}>
                            <div className="mb_form-input">
                                <label className="mb_heading-9" for="month">Select Month</label>
                                <select 
                                    className="mb_form-control" 
                                    name="month"
                                    value={this.state.month}
                                    onChange={this.handleChange}
                                >
                                    <option value="null" >Select Month</option>
                                    {this.renderMonths()}
                                </select>
                            </div>
                        </Col>
                        <Col xs={12} sm={3} lg={2}>
                            <input className="mb_button" type="submit" value="SEARCH" />
                        </Col>
                    </Row>
                </form>
            
        )
    }
}

export default SearchNewsDate