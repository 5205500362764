import React from 'react'
import Table from 'react-bootstrap/Table'
import {BASE_URL} from '../../path'
import {Link} from 'react-router-dom'

//items
import ImageItem from './ImageItem'

//images
import placeholder from '../../assets/img/mba-logo@2x.jpg'

function prependingUrl(url) {
    let link;

    if (!url.match(/^[a-zA-Z]+:\/\//)){
        link = 'http://' + url;
    } else {
        link = url;
    }

    return link;
}

class MembersItem extends React.Component {

    renderCategories(){
        const {categories} = this.props
        return categories.map((item, index) => {
            if(item.title){
                return(
                    <React.Fragment key={index}>
                        {item.title}<br />
                    </React.Fragment>
                )
            }
            return <React.Fragment key={index}></React.Fragment> 
        })
    }

    renderShowroomButton(){
        if(this.props.projects && this.props.projects.length > 0){
            return (
                <div className="_flex">
                    <Link to={`/members/showcase/${this.props.id}`} className="mb_button-gold-fill">MEMBER SHOWCASE</Link>
                </div>
            )
        }
    }
    
    render(){
        const {id, logo, trading_name, member_since, certificate, company_contact_name, company_contact_surname, company_landline_tel, company_cellphone_number, company_street_address, email, website, member_type, company_postal_address, city, projects} = this.props
        const link = prependingUrl(`${website}`)
        return(
            <React.Fragment>
                <Table responsive="lg">
                    <tr>
                        <th colSpan="2">
                            <div className="mb_table-heading-inline">
                                <ImageItem 
                                    image={logo ? `${BASE_URL}${logo}` : placeholder}
                                    alt={trading_name}
                                />
                                <div className="mb_table-heading">
                                    {trading_name}<br />
                                    <span class="mb_heading-9">Member Since:</span> <span className="mb_paragraph">{member_since}</span><br />
                                    <a href={certificate ? `${BASE_URL}${certificate}` : '/'} target="_blank" title="Download Certificate" el="noopener noreferrer">Download Certificate</a>
                                </div>
                                {this.renderShowroomButton()}
                                
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td className="mb_table-col-6">
                            <tr className="mb_table-inner-row">
                                <td className="mb_padding-td">
                                    <span class="mb_heading-9">Contact:</span> {`${company_contact_name} ${company_contact_surname}`}
                                </td>
                            </tr>
                            <tr className="mb_table-inner-row">
                                <td className="mb_padding-td mb_background-td">
                                    <span class="mb_heading-9">Tel:</span> <a href={`tel:${company_landline_tel}`} className="mb_paragraph" title="Call us">{company_landline_tel ? `${company_landline_tel}` : 'N/A'}</a>
                                </td>
                            </tr>
                            <tr className="mb_table-inner-row">
                                <td className="mb_padding-td">
                                   <span class="mb_heading-9">Cell:</span> <a href={`tel:${company_cellphone_number}`} className="mb_paragraph" title="Call us">{company_cellphone_number ? `${company_cellphone_number}` : 'N/A'}</a>
                                </td>
                            </tr>
                        </td>
                        <td  className="mb_table-col-6 mb_padding-td">
                            <span class="mb_heading-9">Street Address:</span><br /> {`${company_street_address} ${city}`}
                        </td>
                    </tr>
                    <tr>
                        <td className="mb_table-col-6">
                            <tr className="mb_table-inner-row">
                                <td className="mb_padding-td mb_background-td">
                                    <span class="mb_heading-9">Email:</span> <a href={`mailto:${email}`} className="mb_paragraph" title="Send an e-mail">{email ? `${email}` : 'N/A'}</a>
                                </td>
                            </tr>
                            <tr className="mb_table-inner-row">
                                <td className="mb_padding-td">
                                    <span class="mb_heading-9">Web:</span> <a href={`${link}`} target="_blank" rel="noopener noreferrer">{website ? `${website}` : 'N/A'}</a>
                                </td>
                            </tr>
                            <tr className="mb_table-inner-row">
                                <td className="mb_padding-td mb_background-td">
                                    <span class="mb_heading-9">Category/ies:</span> {this.renderCategories()}
                                </td>
                            </tr>
                            <tr className="mb_table-inner-row">
                                <td className="mb_padding-td">
                                    <span class="mb_heading-9">Membership Type:</span> {member_type}
                                </td>
                            </tr>
                        </td>
                        <td className="mb_table-col-6 mb_padding-td mb_background-td">
                            <span class="mb_heading-9">Postal Address:</span><br /> {company_postal_address}
                        </td>
                    </tr>
                </Table>
            </React.Fragment>
        )
    }
}

export default MembersItem