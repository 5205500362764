import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import SecondaryHeadingSection from '../components/sections/SecondaryHeadingSection'
import HealthSafetyListSection from '../components/sections/HealthSafetySection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import {connect} from 'react-redux'
import {fetchPageDetails, fetchOptions} from '../actions'

class HealthSafety extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('occupational-health-and-safety')
        if(!this.props.price){
            this.props.fetchOptions(null)
        }
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                <HealthSafetyListSection />
                {this.props.pageData && this.renderBody()}
                {/*<section className="mb_health-safety-section">
                    <Container>
                        <Row>
                            <Col>
                                <h3 class="mb_heading-3-1">MEDICAL CERTIFICATES OF FITNESS</h3>
                                <p className="mb_paragraph">Medical certificates of fitness seem to be a hot topic at the moment. There are very specific responsibilities placed on employers who are undertaking construction work, this includes principal contractors and contractors. One must take into consideration that the regulator only refers to principal contractors and contractors, these contractors are in most instances sub-contractors and covers all levels of sub-contractor.</p>

                                <p className="mb_paragraph">Furthermore, all contractors are classified as employers and are therefore subject to the requirements of the Act and any Regulation that affects their work.</p>

                                <p className="mb_paragraph">It is quite simple, all employers performing construction work as defined in the regulation must ensure that their employees have a “valid medical certificate of fitness specific to the construction work to be performed”. This does not include members of the professional team or visitors to site.</p>

                                <p className="mb_paragraph">Medicals must be issued in the “form” of Annexure 3 which is included in the Construction Regulations and the period for which the medical is valid remains the responsibility of the occupational health practitioner to decide on.</p>

                                <p className="mb_paragraph">No other documentation is required and any documentation made available that contains personal information contravenes the Protection of Personal Information Act. Contractors need to ensure that only the certificate of fitness is available and that no personal information is divulged to anyone on site.</p>

                                <p className="mb_paragraph">It is also the duty of the employer to complete the Annexure 3, before the employee is sent for a medical, so that the OHP can perform the appropriate test on the person based on their exposure and finally declare the person fit for work. Please do not rely on the OHP to complete this document as they have no knowledge of your employees’ exposure.</p>

                            </Col>
                        </Row>
                    </Container>
                </section>*/}
                <section className="mb_health-safety-secondary-heading-section text-center">
                    <Container>
                        <Row>
                            <Col>
                                <h3 class="mb_heading-1-2 text-center">OCCUPATIONAL HEALTH &amp; SAFETY DOCUMENT PRICELIST</h3>
                                <div className="mb_border-bottom-center"></div>
                                <p className="mb_paragraph-2"></p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="mb_health-safety-table-section">
                    <Container>
                        <Row>
                            <Col>
                                <h3 class="mb_heading-3-1">PRICES ARE INCLUSIVE OF VAT</h3>
                                <Table responsive="lg" className="mb_table">
                                    <tr>
                                        <th className="mb_table-col-9 mb_table-col-padding-right">
                                            MEMBERS
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                        <th className="mb_table-col-3 mb_table-col-padding">
                                            PRICE
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="mb_table-col-9 mb_table-col-padding-right mb_td-height">
                                            OHS Manual
                                        </td>
                                        <td className="mb_table-col-3 mb_table-col-padding mb_td-height">
                                            {this.props.price && this.props.price.ohs_manual_member}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="mb_table-col-9 mb_table-col-padding-right mb_td-height">
                                            OHS Act & Regulations 20th Edition
                                        </td>
                                        <td className="mb_table-col-3 mb_table-col-padding mb_td-height">
                                            {this.props.price && this.props.price.ohs_act_regulations_20th_edition_member}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="mb_table-col-9 mb_table-col-padding-right mb_td-height">
                                            OHS USB
                                        </td>
                                        <td className="mb_table-col-3 mb_table-col-padding mb_td-height">
                                           {this.props.price && this.props.price.ohs_usb_member}
                                        </td>
                                    </tr>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table responsive="lg" className="mb_table">
                                    <tr>
                                        <th className="mb_table-col-9 mb_table-col-padding-right">
                                            NON-MEMBERS
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                        <th className="mb_table-col-3 mb_table-col-padding">
                                            PRICE
                                            <div className="mb_border-bottom-light"></div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="mb_table-col-9 mb_table-col-padding-right mb_td-height">
                                            OHS Manual
                                        </td>
                                        <td className="mb_table-col-3 mb_table-col-padding mb_td-height">
                                            {this.props.price && this.props.price.ohs_manual_non_member}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="mb_table-col-9 mb_table-col-padding-right mb_td-height">
                                            OHS Act & Regulations 20th Edition
                                        </td>
                                        <td className="mb_table-col-3 mb_table-col-padding mb_td-height">
                                            {this.props.price && this.props.price.ohs_act_regulations_20th_edition_non_member}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="mb_table-col-9 mb_table-col-padding-right mb_td-height">
                                            OHS USB
                                        </td>
                                        <td className="mb_table-col-3 mb_table-col-padding mb_td-height">
                                            {this.props.price && this.props.price.ohs_usb_non_member}
                                        </td>
                                    </tr>
                                </Table>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['occupational-health-and-safety'],
        price: state.optionsState.data
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails,
    fetchOptions
})(HealthSafety)