import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class BodyArticleSection extends React.Component{
    render(){

        return(
            <React.Fragment>
                <section className="mb_body-article-section">
                        <Container>
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={12}>
                                    <div className="mb_body-article">
                                        <article dangerouslySetInnerHTML={{ __html: this.props.body }}></article>
                                    </div>
                                </Col>
                            </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}

export default BodyArticleSection