import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import Container from 'react-bootstrap/Container';
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import NewsListSection from '../components/sections/NewsListSection'
import NewsDateSection from '../components/sections/NewsDateSection'
import SearchNewsDate from '../components/search/SearchNewsDate'
import {connect} from 'react-redux'
import {fetchPageDetails} from '../actions'

class News extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('news')
    }

    renderList(){ 
        if (window.location.href.indexOf('month') > -1) {
            return <NewsDateSection />
        } else {
            return <NewsListSection />
        }
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <section className="mb_search-members-page-section mb_form">
                    <Container>
                        <SearchNewsDate />
                    </Container>
                </section>
                {this.renderList()}
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['news']
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails
})(News)