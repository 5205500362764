import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import EventsListSection from '../components/sections/EventsListSection'
import EventsPastSection from '../components/sections/EventsPastSection'
import EventsUpcomingSection from '../components/sections/EventsUpcomingSection'
import EventsYearSection from '../components/sections/EventsYearSection'
import SearchEventYear from '../components/search/SearchEventYear'
import {connect} from 'react-redux'
import {fetchPageDetails} from '../actions'

class Events extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('events')
    }

    renderFilter(){ 
        if (window.location.href.indexOf('past') > -1) {
            return(
                <React.Fragment>
                    <Col sm={12} lg={2}>
                        <div className="mb_search-form-left">
                            <Link className="mb_button" to="/events" title="">All</Link>
                        </div>
                    </Col>
                    <Col sm={12} lg={2}>
                        <div className="mb_search-form-left">
                            <Link className="mb_button" to="/events/upcoming" title="">Upcoming</Link>
                        </div>
                    </Col>
                     <Col sm={12} lg={2}>
                         <div className="mb_search-form-left">
                            <Link className="mb_button-gold-fill" to="/events/past" title="">Past</Link>
                        </div>
                    </Col>
                    {/*<SearchEventYear />*/}
                </React.Fragment>
            )
        } else if (window.location.href.indexOf('upcoming') > -1) {
            return(
                <React.Fragment>
                    <Col sm={12} lg={2}>
                        <div className="mb_search-form-left">
                            <Link className="mb_button" to="/events" title="">All</Link>
                        </div>
                    </Col>
                    <Col sm={12} lg={2}>
                        <div className="mb_search-form-left">
                            <Link className="mb_button-gold-fill" to="/events/upcoming" title="">Upcoming</Link>
                        </div>
                    </Col>
                     <Col sm={12} lg={2}>
                         <div className="mb_search-form-left">
                            <Link className="mb_button" to="/events/past" title="">Past</Link>
                        </div>
                    </Col>
                    {/*<SearchEventYear />*/}
                </React.Fragment>
            )
        } else if (window.location.href.indexOf('year') > -1) {
            return(
                <React.Fragment>
                    <Col sm={12} lg={2}>
                        <div className="mb_search-form-left">
                            <Link className="mb_button" to="/events" title="">All</Link>
                        </div>
                    </Col>
                    <Col sm={12} lg={2}>
                        <div className="mb_search-form-left">
                            <Link className="mb_button" to="/events/upcoming" title="">Upcoming</Link>
                        </div>
                    </Col>
                     <Col sm={12} lg={2}>
                         <div className="mb_search-form-left">
                            <Link className="mb_button" to="/events/past" title="">Past</Link>
                        </div>
                    </Col>
                    {/*<SearchEventYear />*/}
                </React.Fragment>
            )
        } else {
            return(
                <React.Fragment>
                    <Col sm={12} lg={2}>
                        <div className="mb_search-form-left">
                            <Link className="mb_button-gold-fill" to="/events" title="">All</Link>
                        </div>
                    </Col>
                    <Col sm={12} lg={2}>
                        <div className="mb_search-form-left">
                            <Link className="mb_button" to="/events/upcoming" title="">Upcoming</Link>
                        </div>
                    </Col>
                     <Col sm={12} lg={2}>
                         <div className="mb_search-form-left">
                            <Link className="mb_button" to="/events/past" title="">Past</Link>
                        </div>
                    </Col>
                     {/*<SearchEventYear />*/}
                </React.Fragment>
            )
        }
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderList(){ 
        if (window.location.href.indexOf('past') > -1) {
            return <EventsPastSection />
        } else if (window.location.href.indexOf('upcoming') > -1) {
            return <EventsUpcomingSection />
        } else if (window.location.href.indexOf('year') > -1) {
            return <EventsYearSection />
        } else {
            return <EventsListSection />
        }
    }

    renderPageContent(){
        return(
            <Nav>
               <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <section className="mb_search-members-page-section mb_form">
                    <Container>
                        <Row>
                            {this.renderFilter()}
                        </Row>
                    </Container>
                </section>
                {this.renderList()}
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['events']
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails
})(Events)