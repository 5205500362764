import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux'
import {fetchMembers} from '../../actions'
import {Pagination} from 'react-laravel-paginex'

//items
import MembersItem from '../items/MembersItem'


class MembersAllSection extends React.Component{


    componentDidMount(){
        console.log(this.props.members);
        if(!this.props.members){
            this.props.fetchMembers(null)
        }
    }

    renderList(){
        return this.props.members.data.map((item, index) => {
            if(item.trading_name){
                return (  
                    <React.Fragment key={index}>
                        <MembersItem
                            id={item.id}
                            logo={item.logo}
                            trading_name={item.trading_name}
                            member_since={item.member_since}
                            certificate={item.certificate}
                            company_contact_name={item.company_contact_name} 
                            company_contact_surname={item.company_contact_surname}  
                            company_landline_tel={item.company_landline_tel}  
                            company_cellphone_number={item.company_cellphone_number}  
                            company_street_address={item.company_street_address}  
                            email={item.email}  
                            website={item.website}  
                            categories={item.categories}  
                            member_type={item.member_type}  
                            company_postal_address={item.company_postal_address}  
                            city={item.city}  
                            company_postal_address_postal_code={item.company_postal_address_postal_code} 
                            projects={item.projects}
                        />
                    </React.Fragment>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    render(){
        return(
            <React.Fragment>
                <section className="mb_search-members-table-section">
                    <Container>
                        <Row>
                            <Col>
                                <h4 class="mb_paragraph">{`Your search for found ${this.props.members && this.props.members.meta.total} results: Page ${this.props.members && this.props.members.meta.current_page} of ${this.props.members && this.props.members.meta.last_page}`} </h4>
                                {this.props.members && this.renderList()}
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Pagination changePage={this.props.fetchMembers} data={this.props.members} containerClass="mb_pagination" />
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        members: state.membersState.data
    }
}

export default connect(mapStateToProps, {
    fetchMembers
})(MembersAllSection)