import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
//persisting the state after page refresh by saving it into local storage
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

//reducers
import newsReducer from './newsReducer'
import newsDateReducer from './newsDateReducer'
import newsHomeReducer from './newsHomeReducer'
import newsArticleReducer from './newsArticleReducer'
import eventsReducer from './eventsReducer'
import eventsPastReducer from './eventsPastReducer'
import eventsUpcomingReducer from './eventsUpcomingReducer'
import eventsYearReducer from './eventsYearReducer'
import eventReducer from './eventReducer'
import faqsReducer from './faqsReducer'
import membersReducer from './membersReducer'
import membersCompanyReducer from './membersCompanyReducer'
import membersCategoryReducer from './membersCategoryReducer'
import membersCategoriesReducer from './membersCategoriesReducer'
import companyNamesReducer from './companyNamesReducer'
import managementReducer from './managementReducer'
import staffReducer from './staffReducer'
import priceListsReducer from './pricelistsReducer'
import responsibilityReducer from './responsibilityReducer'
import partnersReducer from './partnersReducer'
import healthSafetyReducer from './healthSafetyReducer'
import associationsReducer from './associationsReducer'
import pagesReducer from './pagesReducer'
import pageDetailsReducer from './pageDetailsReducer'
import optionsDetailsReducer from './optionsDetailsReducer'
import jointPracticeCommitteeReducer from './jointPracticeCommitteeReducer'
import optionsReducer from './optionsReducer'
import coursesReducer from './coursesReducer'
import coursesHomeReducer from './coursesHomeReducer'
import homeSlidesReducer from './homeSlidesReducer'
import memberReducer from './memberReducer'
import memberProjectsReducer from './memberProjectsReducer'
import memberProjectGalleryReducer from './memberProjectGalleryReducer'
import memberProjectReducer from './memberProjectReducer'
import employmentOpportunitiesReducer from './employmentOpportunitiesReducer'

const persistConfig = {
    key: 'root',
    storage
}

const rootReducer = combineReducers({
	newsState: newsReducer,
	newsDateState: newsDateReducer,
	newsHomeState: newsHomeReducer,
	newsArticleState: newsArticleReducer,
	eventsState: eventsReducer,
	eventsPastState: eventsPastReducer,
	eventsUpcomingState: eventsUpcomingReducer,
	eventsYearState: eventsYearReducer,
	eventState: eventReducer,
	faqsState: faqsReducer,
	membersState: membersReducer,
	membersCompanyState: membersCompanyReducer,
	membersCategoryState: membersCategoryReducer,
	membersCategoriesState: membersCategoriesReducer,
	memberState: memberReducer,
	memberProjectState: memberProjectReducer,
	memberProjectsState: memberProjectsReducer,
	memberProjectGalleryState: memberProjectGalleryReducer,
	companyNamesState: companyNamesReducer,
	managementState: managementReducer,
	staffState: staffReducer,
	priceListsState: priceListsReducer,
	responsibilityState: responsibilityReducer,
	partnersState: partnersReducer,
	healthSafetyState: healthSafetyReducer,
	associationsState: associationsReducer,
	pagesState: pagesReducer,
	pageDetailsState: pageDetailsReducer,
	optionsState: optionsReducer,
	optionsDetailsState: optionsDetailsReducer,
	jointPracticeCommitteeState: jointPracticeCommitteeReducer,
	coursesState: coursesReducer,
	coursesHomeState: coursesHomeReducer,
	homeSlidesState: homeSlidesReducer,
	employmentOpportunitiesState: employmentOpportunitiesReducer,
	form: formReducer,
})

export default persistReducer(persistConfig, rootReducer)