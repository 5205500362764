import {
    FETCH_NEWS_DATE
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_NEWS_DATE:
            return {
                ...state,
                [action.payload.month]: action.payload.data
            }
        default:
            return state
    }
}