import {
  FETCH_EMPLOYMENT_OPPORTUNITIES
} from '../actions/types'

export default (state = [], action) => {
    switch (action.type) {
        case FETCH_EMPLOYMENT_OPPORTUNITIES:
            return action.payload
        default:
            return state
    }
}