import React from 'react'
import {NavLinksMobile, GoldSocialMediaIconsLinks, ContactLinks} from './NavLinks'
import FooterMobileMenu from './FooterMobileMenu'

class MobileMenu extends React.Component {
    render(){
        return(
            <nav className="mb_mobile-side-menu">
                <ul className="mb_ul-menu">
                    <NavLinksMobile />
                </ul>
                <ul className="mb_ul-social">
                    <GoldSocialMediaIconsLinks />
                </ul>
                <ul className="mb_ul-contact">
                    <ContactLinks />
                </ul>
                <FooterMobileMenu />
            </nav>
        )
    }
}

export default MobileMenu