import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import {connect} from 'react-redux'
import {fetchMembers} from '../actions'
import {Pagination} from 'react-laravel-paginex'
import {BASE_URL} from '../path'
import {Link} from 'react-router-dom'

class MemberShowcase extends React.Component{

    componentDidMount(){
        this.props.fetchMembers(null)
    }

    renderItems = () => {
        return this.props.members.data.map(item => {
            if(item.logo){
                return(
                    <Col key={item.registered_as}>
                        <Link to={`/members/showcase/${item.id}`} className="_item">
                            <img src={BASE_URL+item.logo} alt={item.trading_name} />
                        </Link>
                    </Col>
                )
            } else {
                return(
                    <Col key={item.registered_as}>
                        <Link to={`/members/showcase/${item.id}`} className="_item">
                        {item.trading_name}
                        </Link>
                    </Col>
                )
            }
            
        })
        
    }

    renderPageContent(){
        return(
            <React.Fragment>
                <Nav>
                    <HeadingSection 
                        headline="MEMBER SHOWCASE"
                    />
                    <section className="_member-showcase-section">
                        <Container>
                            <Row xs="1" sm="2" lg="4">
                                {this.props.members && this.renderItems()}
                            </Row>
                        </Container>
                        <Container>
                        <Row>
                            <Pagination changePage={this.props.fetchMembers} data={this.props.members} containerClass="mb_pagination" />
                        </Row>
                    </Container>
                    </section>
                </Nav>
            </React.Fragment>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>MBAWC - Member Showcase</title>
                </Helmet>
                {this.renderPageContent()}                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        members: state.membersState.data
    }
}

export default connect(mapStateToProps, {
    fetchMembers
})(MemberShowcase)