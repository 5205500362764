import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {HeadingSecondary} from '../headings'

class SecondaryHeadingSection extends React.Component {

    render(){
        return(
            <section className="mb_secondary-heading-section text-center">
                <Container>
                    <Row>
                        <Col>
                            <HeadingSecondary 
                                text={this.props.headline}
                            />
                            <div className="mb_border-bottom-center"></div>
                            <p className="mb_paragraph-2">{this.props.text}</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            
        )
    }    
}

export default SecondaryHeadingSection