import React from 'react'
import axios from 'axios'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {BASE_URL} from '../../path'
import Recaptcha from 'react-recaptcha';

const initialState = {
  name: '',
  phone: '',
  email: '',
  topic: '',
  message: '',
  nameError: '',
  phoneError: '',
  emailError: '',
  topicError: '',
  messageError: '',
  isVerified: false
};

class ContactForm extends React.Component {

    state = initialState

    handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';
        this.setState({
            [event.target.name]: isCheckbox
            ? event.target.checked
            : event.target.value
        });
     }

    validate = () => {
        let nameError = '';
        let phoneError = '';
        let emailError = '';
        let topicError = '';
        let messageError = '';

        if (!this.state.name) {
            nameError = 'You must enter your name';
        }

        if (!this.state.phone) {
            phoneError = 'You must enter your phone number';
        }

        if (!this.state.email) {
             emailError = 'You must enter your e-mail address';
        } else if (!this.state.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
            emailError = 'You must enter a valid e-mail address';
        }

        if (!this.state.topic) {
             topicError = 'You must enter a topic';
        }

        if (!this.state.message) {
            messageError = 'You must enter a message';
        }

        if ( nameError || phoneError || emailError || topicError || messageError) {
            this.setState({ nameError, phoneError, emailError, topicError, messageError });
            return false;
        }

        return true;
    }

    recaptchaLoaded = () => {
        console.log('capcha successfully loaded');
    }

    verifyCallback = response => {
        if (response) {
            this.setState({
                isVerified: true
            })
            console.log('capcha successfully verified');
        }
    }


    handleSubmit = event => {
        event.preventDefault();
        const isValid = this.validate();
        const verified = this.state.isVerified;
        if (isValid && verified) {
            console.log(this.state)
            axios({
                method: "POST",
                url: `${BASE_URL}/api/contact`,
                data:  this.state
            }).then((response)=>{
                console.log(response)
                if (response.status === 200){
                    this.onSubmitSuccess();
                    this.setState(initialState);
                } else {
                    this.onSubmitFailed();
                }
            })
        }
    }

    onSubmitSuccess = () => {
        window.location.href="/contact/thank-you"
    }
    onSubmitFailed= () => {
        window.location.href="/contact/we-are-sorry"
    }

    render(){
        return(
            <form onSubmit={this.handleSubmit}>
                <Row>
                                           
                    <Col sm={12} lg={6}>
                    	<label className="mb_heading-9" for="name">Your Name</label>
                        <input className="mb_form-control" name="name" type="text" placeholder="Type here..." value={this.state.name} onChange={this.handleChange} />
                        <div className="mb_error">{this.state.nameError}</div>
                    </Col>
                    <Col sm={12} lg={6}>
                    	<label className="mb_heading-9" for="phone">Your Phone</label>
                        <input className="mb_form-control" name="phone" type="tel" placeholder="Type here..." value={this.state.phone} onChange={this.handleChange} />
                        <div className="mb_error">{this.state.phoneError}</div>
                    </Col>
                    <Col sm={12} lg={6}>
                    	<label className="mb_heading-9" for="email">Your Email</label>
                        <input className="mb_form-control" name="email" type="email" placeholder="Type here..." value={this.state.email} onChange={this.handleChange} />
                        <div className="mb_error">{this.state.emailError}</div>
                    </Col>
                    <Col sm={12} lg={6}>
                    	<label className="mb_heading-9" for="topic">Your Topic</label>
                        <input className="mb_form-control" name="topic" type="text" placeholder="Type here..." value={this.state.topic} onChange={this.handleChange} />
                        <div className="mb_error">{this.state.topicError}</div>
                    </Col>
                    <Col  sm={12} lg={12}>
                    	<label className="mb_heading-9" for="massage">Your Message</label>
                        <textarea className="mb_form-control" name="message" placeholder="Type here..." value={this.state.message} onChange={this.handleChange} ></textarea>
                        <div className="mb_error">{this.state.messageError}</div>
                    </Col>
                    <Col  sm={12} lg={12}>
                        <Recaptcha
                            sitekey="6Ldhdt4ZAAAAAPh8QgtgUIigSAcJv2FRdHw3nOPd"
                            render="explicit"
                            onloadCallback={this.recaptchaLoaded}
                            verifyCallback={this.verifyCallback}
                        />
                        <div className="mb_error"></div>
                    </Col>
                    <Col className="mb_display-flex mb_justifyContent-center">
                        <input className="mb_button" type="submit" value="Submit" />
                    </Col>                        
                
                </Row>
            </form>
        )
    }
}

export default ContactForm