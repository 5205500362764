import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class BenefitsItem extends React.Component {
    
    render(){
        const {image, title, description, link} = this.props
        return(
            <Col sm={12} lg={6}>
                <a href={link}>
                    <Row className="mb_benefits-section-row">
                        <Col xs={3} sm={3} lg={3}>
                            <figure>
                                <img src={image} alt={`${title}`} title={`${title}`} />
                            </figure>
                        </Col>
                        
                        <Col xs={9} sm={9} lg={9}>
                            <article>
                                <h3 href={link} className="mb_heading-3-1" >{title}</h3>
                                <p className="mb_paragraph-3">{description}</p>
                            </article>
                        </Col>
                    </Row>
                </a>
            </Col>
        )
    }
}

export default BenefitsItem