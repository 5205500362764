import React from 'react'

class ImageItem extends React.Component {
    
    render(){
        const {image, alt} = this.props
        return(
            <figure>
                <img src={image} alt={alt} />
            </figure> 
        )
    }
}

export default ImageItem