import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ButtonFillColor} from '../buttons'

class SignUpSection extends React.Component {
    
    render(){
        return(
            <section className="mb_signup-section">
                <Container>
                    <Row>
                        <Col sm={12} lg={12}>
                            <article>
                                <h2 className="mb_heading-2">
                                    JOIN MBA
                                </h2>
                                <p className="mb_paragraph">The MBAWC is a registered Trade Association for Employers in the Building Industry</p>

                                <ButtonFillColor link="/apply-for-membership" className="mb_button mt-3" title="Apply Npw" text="Apply Now" />
                            </article>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default SignUpSection