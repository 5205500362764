import {
    FETCH_MEMBER_PROJECT
} from '../actions/types'

const initialState = {}

const memberProjectReducer = (state = initialState, action) => {    
    switch (action.type){
        case FETCH_MEMBER_PROJECT:
            return { ...state, [action.id]: action.payload }
        default:
            return state
    }
}

export default memberProjectReducer