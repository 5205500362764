import React from 'react'
import Logo from './Logo'
import {SocialMediaIconsLinks, ContactLinks} from './NavLinks'
import WidescreenNav from './WidescreenNav'
import MobileMenu from './MobileMenu'
import { MenuButton } from './buttons'
import Footer from './Footer'
import Container from 'react-bootstrap/Container';


class Nav extends React.Component {

    state = {
        menu: false,
        scrolled: false,
        isHome: false,
    }

    componentDidMount(){
        window.addEventListener('scroll', this.onScroll)
        if (window.location.pathname === '/') {
            this.setState({
                isHome: true
            })
        } else {
            this.setState({
                isHome: false
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
    }

    onScroll = () => {
        if(window.scrollY > 0){
            if(!this.state.scrolled){
                this.setState({
                    scrolled: true
                })
            }
            
        } else {
            if(this.state.scrolled){
                this.setState({
                    scrolled: false
                })
            }
        }
    }

    onOpenMenu = () => {
        this.setState({
            menu: !this.state.menu
        })
    }

    render(){
        return(
            <React.Fragment>
                <header className={`mb_header ${this.state.scrolled ? 'mb_transformed' : ''}`}>
                    <div className={`mb_header-top ${this.state.scrolled ? 'mb_transformed' : ''}  ${this.state.isHome ? 'mb_header-top-home' : ''}`}>
                        <Container>
                            <div className="mb_item mb_item-right">
                                <ul className="mb_ul-social">
                                    <SocialMediaIconsLinks />
                                </ul>
                            </div>
                            <div className="mb_item mb_item-center">
                                <ul className="mb_ul-contact">
                                    <ContactLinks />
                                </ul>
                            </div>
                        </Container>
                    </div>
                    <div className={`mb_header-bottom ${this.state.scrolled ? 'mb_transformed' : ''}`}>
                        <Container>
                            <div className="mb_item mb_item-left">
                                <Logo />
                            </div>
                            <div className="mb_item mb_item-center">
                                <WidescreenNav />
                            </div>
                            <div className="mb_item mb_item-right">
                                <MenuButton 
                                    menu={this.state.menu}
                                    onClick={this.onOpenMenu}
                                />
                                <div className={`mb_flyopen-menu ${this.state.menu ? 'open' : ''}`}>
                                    <MobileMenu onClick={this.onOpenMenu} />
                                </div>
                            </div>
                        </Container>
                    </div>
                </header>
                <main>{this.props.children}</main>

                <Footer />

            </React.Fragment>
            
        )
    }
}

export default Nav