import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import {connect} from 'react-redux'
import {fetchMember, fetchMemberProjects} from '../actions'
import {BASE_URL} from '../path'
import { Link } from 'react-router-dom';

class MemberShowcaseProjects extends React.Component{

    componentDidMount(){
        this.props.fetchMember(this.props.match.params.id)
        this.props.fetchMemberProjects(this.props.match.params.id)
    }

    renderItems(){
        if(this.props.projects.length > 0){
            return (
                <Container>
                    <Row xs="1" sm="2" lg="4">
                        {this.props.projects.map(item => {
                            return (
                                <Col>
                                    <Link 
                                        className="_item" 
                                        to={`/members/showcase/${this.props.match.params.id}/projects/${item.id}`}
                                        style={{
                                            backgroundImage: `url(${BASE_URL+item.image})`
                                        }}
                                    >
                                        <div className="_caption">
                                            <p><strong>{item.title}</strong></p>
                                            <p><i>{item.month} {item.year}</i></p>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            )
            
        } else {
            return <p style={{
                textAlign: 'center',
                width: '100%'
            }}>No projects available for this member</p>
        }
        
    }

    renderPageContent(){
        return(
            <React.Fragment>
                <Nav>
                    <section className="mb_heading-section mb_showroom-heading-section text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    {
                                        this.props.member.logo && (
                                            <img className="_logo" src={BASE_URL+this.props.member.logo} alt="" />
                                        )
                                    }
                                    <h1 className="mb_heading-1-2-2 text-center">{this.props.member.trading_name}</h1>
                                    <div className="mb_border-bottom-center">
                                        </div>
                                        <p className="mb_paragraph-2"></p>
                                    </div>
                            </div>
                        </div>
                    </section>
                    <section className="_member-showcase-projects-section">
                        {this.props.projects && this.renderItems()}
                    </section>
                </Nav>
            </React.Fragment>
        )
    }

    render(){

        if(!this.props.member){
            return <></>
        }

        return(
            <React.Fragment>
                <Helmet>
                    <title>MBAWC - Member Showcase Projects - {this.props.member.trading_name}</title>
                </Helmet>
                {this.renderPageContent()}                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        member: state.memberState[ownProps.match.params.id],
        projects: state.memberProjectsState[ownProps.match.params.id]
    }
}

export default connect(mapStateToProps, {
    fetchMember,
    fetchMemberProjects
})(MemberShowcaseProjects)