import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import AccordionContext from 'react-bootstrap/AccordionContext'
import {connect} from 'react-redux'
import {fetchFaqs, fetchPageDetails} from '../actions'
import {Pagination} from 'react-laravel-paginex'

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = React.useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      className={`${isCurrentEventKey ? 'mb_hide-read-more-link' : ''}`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}


class Faq extends React.Component{

    componentDidMount(){
        if(!this.props.faqs){
            this.props.fetchFaqs(null)
        }
        this.props.fetchPageDetails('faq')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderList(){
        return this.props.faqs.data.map((item, index) => {
            if(item.question){
            return (      
                <li>
                    <ContextAwareToggle eventKey={`${index}`}>
                        <h2 id={index} className="mb_heading-3-1">{item.question}</h2>
                        <span className="mb_read-more-link">Read More</span>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey={`${index}`}>
                        <article dangerouslySetInnerHTML={{ __html: item.answer }}></article>
                    </Accordion.Collapse>
                    <div className="mb_border-bottom-light"></div>
                </li>
            )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <section className="mb_faq-section">
                    <Container>
                        <Row>
                            <Col>
                                <Accordion>
                                    <ul>
                                       {this.props.faqs && this.renderList()}
                                    </ul>
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Pagination changePage={this.props.fetchFaqs} data={this.props.faqs} containerClass="mb_pagination" />
                        </Row>
                    </Container>
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        faqs: state.faqsState.data,
        pageData: state.pageDetailsState['faq']
    }
}

export default connect(mapStateToProps, {
    fetchFaqs,
    fetchPageDetails
})(Faq)