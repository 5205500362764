import React from 'react'
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux'
import {fetchCompanyName} from '../../actions'
import history from '../../history'

const INPUT_TIMEOUT = 250;

class Search extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            company: '',
            predictions: [],
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        if(!this.props.companyName){
            this.props.fetchCompanyName(null)
        }
    }

    getPredictions(company) {
         return this.props.companyName.map((item, index) => {
            if(item.trading_name){
                return [
                    item.trading_name
                ].filter(item => item.toLowerCase().indexOf(company.toLowerCase()) !== -1);
            }
            return <React.Fragment key={index}></React.Fragment>
        })
    }

    handleChange(e) {
        // clear timeout when input changes value
        clearTimeout(this.timeout);
        let company = e.target.value;
        this.setState({
            company
        });

        if (company.length > 0) {
            // make delayed api call
            this.timeout = setTimeout(() => {
                const predictions = this.getPredictions(company);
                this.setState({
                    predictions
                });
            }, INPUT_TIMEOUT);
        } else {
            this.setState({
                predictions: []
            });
        }
    }

    handleClick = event => {
        event.preventDefault();
        let company = event.target.id;
        this.setState({
            company
        });
    };

    handleSubmit = event => {
        if(this.state.company !== ''){
            history.push(`/members${URLSearchParams(this.state.company)}`)
        } else {
            history.push('/members')
        }
    }

    renderList(){
        return this.state.predictions.map((item, index) => {
            if(item){
                return(
                    <React.Fragment >
                        <li key={index} id={item} onClick={this.handleClick} >{item}</li>
                    </React.Fragment>
                )
            }
            return <React.Fragment key={index}></React.Fragment>
        })
    }

    render(){
        return(                      
            <Col sm={12} lg={6}>
                <form className="mb_search-form" onSubmit={this.handleSubmit}>
                    <div className="mb_form-input">
                        <label className="mb_heading-9" for="company">Search by Company</label>
                        <input className="mb_form-control" name="company" type="text" placeholder="Type here..." value={this.state.company} onChange={this.handleChange} autoComplete="off"/>
                        <ul> 
                            {this.state.predictions && this.renderList()} 
                        </ul> 
                    </div>
                    <input className="mb_button" type="submit" value="SEARCH" />
                </form>
            </Col>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        companyName: state.companyNamesState.data
    }
}

export default connect(mapStateToProps, {
    fetchCompanyName
})(Search)