import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux'
import {fetchPageDetails} from '../actions'

class Guidelines extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('guidelines-to-successful-building')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <section className="mb_about-section">
                    {/*<Container>
                        <Row>
                            <Col>
                                <h2 className="mb_heading-3-1">PLANNING</h2>

                                <p className="mb_paragraph">Before calling in a contractor for a quotation, draw up a specific detailed plan of what you want done and the amount you can afford to spend. For substantial work, obtain a detailed cost estimate and adjust your requirements to suit your budget. Get a qualified person to draw plans, determine specifications and submit them to the local authority for approval.</p>

                                <h2 className="mb_heading-3-1 mb_margin-top">CHOOSE A RELIABLE BUILDING CONTRACTOR</h2>

                                <p className="mb_paragraph">Ensure that your contractor is registered with the necessary legal or statutory bodies such as: Workman’s Compensation Insurance; Bargaining Council for the Building Industry; and the Receiver of Revenue.</p>

                                <p className="mb_paragraph">Obtain references from all contractors and be aware that contractors are not obliged to be members of the MBA but that MBA members will give you a level of protection.</p>

                                <p className="mb_paragraph">Current legislation also requires that if you are building a new house your contractor be registered with the National Home Builders Registration Council (NHBRC). Registration with the NHBRC for alterations, additions or renovations is not required. Registration takes about 5 weeks, so don’t be caught out by starting to build and then finding out that the bank wants a certificate of registration before making the first payment.</p>

                                <p className="mb_paragraph">Be extremely wary of engaging persons who claim to be “project managers” and say that they can save you thousands of rands. Ensure that a Project Manager, if not a member of the MBA, is in possession of a professional qualification.</p>

                                <h2 className="mb_heading-3-1 mb_margin-top">THE PRICE</h2>

                                <p className="mb_paragraph">Obtain quotations from at least three reputable contractors. To compare costs, make sure that each contractor is quoting on the same written specifications and conditions and ensure that the quotation is inclusive of VAT. Be cautious of unrealistically low quotations.</p>

                                <p className="mb_paragraph">Do not commence any work whilst the price is still under negotiation.</p>

                                <h2 className="mb_heading-3-1 mb_margin-top">ACCEPTING THE QUOTATION</h2>

                                <p className="mb_paragraph">Don’t sign acceptance unless the contractor’s offer is firm, in writing, clear, covers all your requirements and is signed. Make sure that the agreement includes the starting date, the approximate duration of the work, the anticipated completion date, cleaning up during the work, including the disposal of waste or rubble, the order in which the contractor will proceed while on your premises and payment details. Protect all parties by making use of a MBSA or MBA approved Building Contract.</p>

                                <h2 className="mb_heading-3-1 mb_margin-top">INSURANCES</h2>

                                <p className="mb_paragraph">Your contractor should be insured for Public Liability and Contract Insurance. Appropriate insurance should be agreed to cover damage to your existing building and contents. Inform your own insurance company that you are having building work or renovations done.</p>

                                <h2 className="mb_heading-3-1 mb_margin-top">DEFECTS</h2>

                                <p className="mb_paragraph">If you are unable to determine defects and quality workmanship, it is advisable to employ a building consultant or architect to monitor the construction work for the duration of the contract. Ensure that the appointed person has the necessary expertise to undertake construction related inspections.</p>

                                <p className="mb_paragraph">Defects during the construction stage must be dealt with in terms of your contract document.</p>

                                <h2 className="mb_heading-3-1 mb_margin-top">EXTRA WORK AND / OR VARIATIONS</h2>

                                <p className="mb_paragraph">Establish the cost of any extra work requested from the contractor in writing before the work is carried out and confirm any changes in writing, with both parties signing acceptance.</p>

                                <h2 className="mb_heading-3-1 mb_margin-top">PAYMENT</h2>

                                <p className="mb_paragraph">You should normally not be required to make a deposit before work commences or be asked to pay workers wages during the contract. With fairly large jobs, interim payments on completion of certain sections may be agreed upon or specified in a contract or agreement. A request for a progress payment should be accompanied by an invoice detailing the % and value of the work completed. For a small job, payment is normally made in one lump sum when the work is satisfactorily completed. Before making final payment, inspect the completed work and put your complaints or defects regarding workmanship or materials used, in writing. Whilst it is acceptable to withhold money for defects or incomplete work, it is extremely unfair to withhold a large sum of money for minor defects.</p>

                                <h2 className="mb_heading-3-1 mb_margin-top">DISPUTES WITH NON-MBA CONTRACTORS</h2>

                                <p className="mb_paragraph">Clients have little recourse to Local Authorities, Financial Institutions or Building Inspectors for building, electrical or plumbing problems with contractors. The MBA will be unable to assist the client unless a MBSA approved contract has been used and the above steps have been followed. The only recourse available will then be the Consumer Council, Small Claims Court, Legal Resource Centre or litigation through the courts.</p>

                                <h2 className="mb_heading-3-1 mb_margin-top">COMPLAINTS PROCEDURE FOR MBA CONTRACTORS</h2>

                                <p className="mb_paragraph">In the event of complaints against a member company who has refused to attend to said complaints within a reasonable period, you may appeal to the MBA, provided the member is a registered member and your complaint has been submitted to the MBA in writing. Please address all complaints, in writing, to:</p>

                                <h2 className="mb_heading-3-1 mb_margin-top mb_margin-bottom">The Executive Director, Master Builders Association<br /> 
                                    PO Box 382, Rondebosch 7701<br />
                                    Email: <a href="mailto:info@mbawc.org.za?subject=MBAWC Enquiry" className="mb_email" title="Send us an e-mail">info@mbawc.org.za</a></h2>

                                <p className="mb_paragraph">The MBA undertakes to thoroughly investigate your complaint, and in this regard, the Association will endeavour to resolve any complaints received in a fair and equitable manner, with due regard to the circumstances and industry norm.</p>

                                <p className="mb_paragraph">It must be noted that the MBA is a voluntary Association of contractors, suppliers, manufacturers and consultants. There is no legal requirement that members must belong to the Association and as such, the Association does not have the jurisdiction to be prescriptive.</p>

                                <p className="mb_paragraph">The Association cannot, thus, guarantee that the complaint will be solved to the satisfaction of one, or both parties, but will endeavour by all means at its disposal to do so.</p>

                            </Col>
                        </Row>
                    </Container>*/}
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['guidelines-to-successful-building']
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails
})(Guidelines)