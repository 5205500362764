export const FETCH_NEWS = 'FETCH_NEWS'
export const FETCH_NEWS_DATE = 'FETCH_NEWS_DATE'
export const FETCH_NEWS_HOME = 'FETCH_NEWS_HOME'
export const FETCH_NEWS_ARTICLE = 'FETCH_NEWS_ARTICLE'
export const FETCH_EVENTS = 'FETCH_EVENTS'
export const FETCH_EVENTS_PAST = 'FETCH_EVENTS_PAST'
export const FETCH_EVENTS_UPCOMING = 'FETCH_EVENTS_UPCOMING'
export const FETCH_EVENTS_YEAR = './FETCH_EVENTS_YEAR'
export const FETCH_EVENT = 'FETCH_EVENT'
export const FETCH_FAQS = 'FETCH_FAQS'
export const FETCH_COMPANY_NAME = 'FETCH_COMPANY_NAME'
export const FETCH_MANAGEMENT = 'FETCH_MANAGEMENT'
export const FETCH_STAFF = 'FETCH_STAFF'
export const FETCH_PRICELIST = 'FETCH_PRICELIST'
export const FETCH_SOCIAL_RESPONSIBILITY = 'FETCH_SOCIAL_RESPONSIBILITY'
export const FETCH_PARTNERS = 'FETCH_PARTNERS'
export const FETCH_HEALTH_SAFETY = 'FETCH_HEALTH_SAFETY'
export const FETCH_ASSOCIATIONS = 'FETCH_ASSOCIATIONS'
export const FETCH_JOINT_PRACTICE_COMMITTEE = 'FETCH_JOINT_PRACTICE_COMMITTEE'
export const FETCH_PAGES = 'FETCH_PAGES'
export const FETCH_PAGE_DETAILS = 'FETCH_PAGE_DETAILS'
export const FETCH_OPTIONS = 'FETCH_OPTIONS'
export const FETCH_OPTION_DETAILS = 'FETCH_OPTION_DETAILS'
export const FETCH_COURSES = 'FETCH_COURSES'
export const FETCH_COURSES_HOME = 'FETCH_COURSES_HOME'
export const FETCH_HOME_SLIDES = 'FETCH_HOME_SLIDES'
export const FETCH_EMPLOYMENT_OPPORTUNITIES = 'FETCH_EMPLOYMENT_OPPORTUNITIES'

//members
export const FETCH_MEMBERS = 'FETCH_MEMBERS'
export const FETCH_MEMBERS_COMPANY = 'FETCH_MEMBERS_COMPANY'
export const FETCH_MEMBERS_CATEGORY = 'FETCH_MEMBERS_CATEGORY'
export const FETCH_MEMBERS_CATEGORIES = 'FETCH_MEMBERS_CATEGORIES'
export const FETCH_MEMBER = 'FETCH_MEMBER'
export const FETCH_MEMBER_PROJECT = 'FETCH_MEMBER_PROJECT'
export const FETCH_MEMBER_PROJECTS = 'FETCH_MEMBER_PROJECTS'
export const FETCH_MEMBER_PROJECT_GALLERY = 'FETCH_MEMBER_PROJECT_GALLERY'