import React from 'react'
import {
    Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom'
import history from './history'

//pages
import Home from './pages/Home'
import About from './pages/About'
import BenefitsOfMembership from './pages/BenefitsOfMembership'
import CodeOfPractice from './pages/CodeOfPractice'
import CorporatePartners from './pages/CorporatePartners'
import CorporateSocialResponsibility from './pages/CorporateSocialResponsibility'
import Management from './pages/Management'
import Staff from './pages/Staff'
import ContractDocumentPricelist from './pages/ContractDocumentPricelist'
import JointPracticeCommitee from './pages/JointPracticeCommitee'
import MbaInsurance from './pages/MbaInsurance'
import HealthSafety from './pages/HealthSafety'
import TelephoneNumbers from './pages/TelephoneNumbers'
import TrainingSkills from './pages/TrainingSkills'
import News from './pages/News'
import NewsArticle from './pages/NewsArticle'
import Events from './pages/Events'
import Event from './pages/Event'
import Faq from './pages/Faq'
import Guidelines from './pages/Guidelines'
import Members from './pages/Members'
import SignUp from './pages/SignUp'
import ContactUs from './pages/ContactUs'
import ThankYou from './pages/ThankYou'
import WeAreSorry from './pages/WeAreSorry'
import WebsiteDisclaimer from './pages/WebsiteDisclaimer'
import MemberShowcase from './pages/MemberShowcase'
import MemberShowcaseProjects from './pages/MemberShowcaseProjects'
import MemberShowcaseProjectGallery from './pages/MemberShowcaseProjectGallery'
import PageNotFound from './pages/PageNotFound'
import EmploymentOpportunities from './pages/EmploymentOpportunities'

import './assets/scss/App.scss'
import ScrollToTop from './components/ScrollToTop'

class App extends React.Component {
    render(){
        return(
            <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
                <ScrollToTop />              
                <Switch>         
                    <Route exact path="/" component={Home} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/about/benefits-of-membership" component={BenefitsOfMembership} />
                    <Route exact path="/about/code-of-practice" component={CodeOfPractice} />
                    <Route exact path="/about/corporate-partners" component={CorporatePartners} />
                    <Route exact path="/about/corporate-social-responsibility" component={CorporateSocialResponsibility} />
                    <Route exact path="/about/management" component={Management} />
                    <Route exact path="/about/staff" component={Staff} />
                    <Route exact path="/services" component={ContractDocumentPricelist} />
                    <Route exact path="/services/joint-practice-commitee" component={JointPracticeCommitee} />
                    <Route exact path="/services/mba-insurance" component={MbaInsurance} />
                    <Route exact path="/services/occupational-health-and-safety" component={HealthSafety} />
                    <Route exact path="/services/contacts" component={TelephoneNumbers} />
                    <Route exact path="/services/training-and-skills-development" component={TrainingSkills} />
                    {/*<Route exact path="/employment-opportunities" component={EmploymentOpportunities} />*/}
                    <Route exact path="/news" component={News} />
                    <Route exact path="/news/:slug" component={NewsArticle} />
                    <Route exact path="/events" component={Events} />
                    <Route exact path="/events/past" component={Events} />
                    <Route exact path="/events/upcoming" component={Events} />
                    <Route exact path="/events/:slug" component={Event} />
                    <Route exact path="/faq" component={Faq} />
                    <Route exact path="/faq/guidelines-to-successful-building" component={Guidelines} />
                    <Route exact path="/members" component={Members} />
                    <Route exact path="/members/showcase" component={MemberShowcase} />
                    <Route exact path="/members/showcase/:id" component={MemberShowcaseProjects} />
                    <Route exact path="/members/showcase/:id/projects/:project_id" component={MemberShowcaseProjectGallery} />
                    <Route exact path="/members?search=all" component={Members} />
                    <Route exact path="/members:keywords" component={Members} />
                    <Route exact path="/members:search_name" component={Members} />
                    <Route exact path="/apply-for-membership" component={SignUp} />
                    <Route exact path="/apply-for-membership/thank-you" component={ThankYou} />
                    <Route exact path="/apply-for-membership/we-are-sorry" component={WeAreSorry} />
                    <Route exact path="/contact" component={ContactUs} />
                    <Route exact path="/contact/thank-you" component={ThankYou} />
                    <Route exact path="/contact/we-are-sorry" component={WeAreSorry} />
                    <Route exact path="/website-disclaimer" component={WebsiteDisclaimer} />
                    <Route component={PageNotFound} />                    
                </Switch>
            </Router>
        )
    }
}

export default App