import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NewsItem from '../items/NewsItem'
import {connect} from 'react-redux'
import {fetchNewsDate} from '../../actions'
import {Pagination} from 'react-laravel-paginex'

const urlParams = new URLSearchParams(window.location.search);
const yearParam = urlParams.get('year');
const monthParam = urlParams.get('month');

class NewsDateSection extends React.Component{

    state = {
        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
    }

    renderMonths(){
        return this.state.months.map((item, index) => {
            if(index + 1 == monthParam){
                return(
                    <React.Fragment key={index}>{item}</React.Fragment>
                )
            }
            return <React.Fragment key={index}></React.Fragment>
        })
    }

    componentDidMount(){
        this.props.fetchNewsDate(yearParam, monthParam)
    }

    renderList(){
        return this.props.news.data.map((item, index) => {
            if(item.headline){
                return (
                    <React.Fragment>
                    <Col sm={12} md={6} lg={4} xl={4} >
                        <NewsItem
                            date={item.published}
                            text={item.headline}
                            link={`/news/${item.slug}`}
                        />
                    </Col>
                    </React.Fragment>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    render(){
        return(
            <section className="mb_news-list-section">
                <Container>
                    <Row>
                        <Col className="mb_search-results">
                            <h4 class="mb_paragraph">{`Found ${this.props.news && this.props.news.meta.total} news articles for `} <span class="mb_heading-9">{this.renderMonths()} {`${yearParam == null ? yearParam : '2020'}`}:</span> Page {`${this.props.news && this.props.news.meta.current_page} of ${this.props.news && this.props.news.meta.last_page}`}</h4>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        {this.props.news && this.renderList()}
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Pagination changePage={this.props.fetchNewsDate} data={this.props.news} containerClass="mb_pagination" />
                    </Row>
                </Container>
            </section>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        news: state.newsDateState[yearParam, monthParam]
    }
}

export default connect(mapStateToProps, {
    fetchNewsDate
})(NewsDateSection)