import {
    FETCH_MEMBER
} from '../actions/types'
import _ from 'lodash';

const initialState = {}

const memberReducer = (state = initialState, action) => {    
    switch (action.type){
        case FETCH_MEMBER:
            return { ...state, [action.payload.id]: action.payload }
        default:
            return state
    }
}

export default memberReducer