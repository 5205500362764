import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import {HeadingPrimary} from '../components/headings'
import BodyArticleSection from '../components/sections/BodyArticleSection'
// import MembershipForm from '../components/forms/MembershipForm'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux'
import {fetchPageDetails} from '../actions'

//pdfs
import pdf1 from '../assets/pdfs/2-MBAWC-Application-Form-2024_2025.pdf'
import pdf2 from '../assets/pdfs/1-MBAWC-Cover-Letter-to-New-Applicants-2024_2025.pdf'
import pdf3 from '../assets/pdfs/4-MBAWC-Value-Proposition-2024.pdf'
import pdf4 from '../assets/pdfs/5-BIBC-Advantages-For-Employees.pdf'
import pdf5 from '../assets/pdfs/6-MBAWC-Constitution-Ratified-September-2021.pdf'
import pdf6 from '../assets/pdfs/7-MBAWC-Code-of-Conduct-October-2021.pdf'
import pdf7 from '../assets/pdfs/MBAWC.zip'
import pdf8 from '../assets/pdfs/3-MBAWC-Annexture-A-Category-Listings-2024_2025.pdf'

class SignUp extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('apply-for-membership')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderPageContent(){
        return(
            <Nav>
                <section className="mb_heading-section text-center">
                    <Container>
                        <Row>
                            <Col>
                                <HeadingPrimary 
                                    text={this.props.pageData && this.props.pageData.data.headline}
                                />
                                <div className="mb_border-bottom-center"></div>
                                <p className="mb_paragraph-2">{this.props.pageData && this.props.pageData.data.subheading}</p>
                                <div className="mb_paragraph-div">
                                    <p className="mb_paragraph-2">
                                        <a href={pdf1} download="MBAWC Membership Application Form Document" className="mb_heading-link" title="Download Membership Application" >Click here</a> to download the Membership Application form.<br />
                                        <a href={pdf2} download="MBAWC Membership  Cover Letter for new applicants Document" className="mb_heading-link" title="Download Cover Letter for new applicants" >Click here</a> to download the Cover Letter for new applicants.<br />
                                        <a href={pdf3} download="MBAWC Value Proposition document" className="mb_heading-link" title="Download MBAWC Value Proposition document" >Click here</a> to download the MBAWC Value Proposition document.<br />
                                        <a href={pdf4} download="MBAWC BIBC Advantages for employees Document" className="mb_heading-link" title="Download BIBC Advantages for employees" >Click here</a> to download the BIBC Advantages for employees.<br />
                                        <a href={pdf5} download="MBAWC MBAWC Constitution Document" className="mb_heading-link" title="Download MBAWC Constitution" >Click here</a> to download the MBAWC Constitution.<br />
                                        <a href={pdf6} download="MBAWC Code of Conduct Document" className="mb_heading-link" title="Download MBAWC Code of Conduct" >Click here</a> to download the MBAWC Code of Conduct.<br />
                                        <a href={pdf6} download="MBAWC Annexture A Category Listings 2024/2025" className="mb_heading-link" title="Download MBAWC Annexture A Category Listings 2024/2025" >Click here</a> to download the MBAWC Annexture A Category Listings 2024/2025.<br />
                                        
                                    </p>
                                    <p className="mb_paragraph-2"><a href={pdf7} download="MBAWC All Documents" className="mb_heading-link" title="Download all documents" >Click here</a> to download all of the above documents.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {this.props.pageData && this.renderBody()}
                <section className="mb_apply-membership-page-section mb_form">
                    {/*<Container>
                        <Row className="mb_contact-page-row">
                            <Col sm={12}lg={12}>
                                <h4 className="mb_heading-3-1">
                                    APPLICATION FORM
                                </h4>
                                <div class="mb_border-bottom-left"></div>
                                <MembershipForm />
                            </Col>
                        </Row>
                    </Container>*/}
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['apply-for-membership']
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails
})(SignUp)