import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import {connect} from 'react-redux'
import {fetchPageDetails, fetchCoursesHome} from '../actions'

//sections
import TopBannerSliderSection from '../components/sections/TopBannerSliderSection'
import SecondaryHeadingSection from '../components/sections/SecondaryHeadingSection'
import BenefitsSection from '../components/sections/BenefitsSection'
import NewsHomeSection from '../components/sections/NewsHomeSection'
import TrainingSkillsSection from '../components/sections/TrainingSkillsSection'
// import SocialFeedSection from '../components/sections/SocialFeedSection'
import SignUpSection from '../components/sections/SignUpSection'
import {BASE_URL} from '../path'

//items
import BenefitsItem from '../components/items/BenefitsItem'
import TrainingSkillsItem from '../components/items/TrainingSkillsItem'


//buttons
import {ButtonLink} from '../components/buttons'


//images
import insurancesIcon from '../assets/img/icons/insurances.svg'
import labourRelationsIcon from '../assets/img/icons/labour-relations.svg'
import healthAndSafetyIcon from '../assets/img/icons/health-and-safety.svg'
import skillsTrainingIcon from '../assets/img/icons/skills-training.svg'

import placeholderImage from '../assets/img/placeholders/products.jpg'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Home extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('home')
        if(!this.props.courses){
            this.props.fetchCoursesHome(null)
        }
    }

    renderCourseList(){
        return this.props.courses.data.map((item, index) => {
            if(item.title){
                return (
                    <React.Fragment key={index}>
                        <TrainingSkillsItem 
                            image={`${item.image ? `${BASE_URL}${item.image}` : placeholderImage}`}
                            title={item.title}
                            subtitle={item.subtitle}
                            description={item.description}
                            body={item.body}
                            contact_person={item.contact_person}
                            email={item.email}
                            pdf={`${item.pdf ? `${BASE_URL}${item.pdf}` : '/'}`}
                        />
                    </React.Fragment>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderPageContent(){
        return(
            <Nav>
                <TopBannerSliderSection />
                <SignUpSection />
                <SecondaryHeadingSection 
                    headline="MBA BENEFITS"
                    text="The Master Builders and Allied Trades’ Association Western Cape, “known as the MBA”, is a registered Trade Association for the Employers in the building industry."
                />
                <BenefitsSection>
                    <BenefitsItem
                        image={insurancesIcon}
                        title="INSURANCES"
                        description="Free expert advice and guidance from our inhouse MBA insurance company specialising in the Building Industry."
                        link="/services/mba-insurance"
                     />
                     <BenefitsItem
                        image={labourRelationsIcon}
                        title="LABOUR RELATIONS"
                        description="The MBAWC keeps Members updated in terms of the latest developments in the Labour Relations Act and will.."
                        link="/about/benefits-of-membership"
                        // description="The MBAWC keeps Members updated in terms of the latest developments in the Labour Relations Act and will advise you on how to operate fairly and strictly within the Act. Membership of the MBAWC gives you the opportunity to benefit from collective bargaining and representation in both the labour market and the industrial sector.."
                     />
                     <BenefitsItem
                        image={healthAndSafetyIcon}
                        title="OCCUPATIONAL HEALTH AND SAFETY"
                        description="Our team of OHS Advisors are happy to assist and advise you on any aspect relating to OHS."
                        link="/services/occupational-health-and-safety"
                     />
                     <BenefitsItem
                        image={skillsTrainingIcon}
                        title="SKILLS TRAINING"
                        description="Access to MBAWC preferred Training Providers at special rates for the most up to date training of employees."
                        link="/services/training-and-skills-development"
                     />
                     <ButtonLink
                        text="MORE BENEFITS"
                        link="/about/benefits-of-membership"
                    />
                </BenefitsSection>
                <NewsHomeSection />
                <SecondaryHeadingSection 
                    headline="TRAINING & SKILLS DEVELOPMENT"
                    text=""
                />
                <TrainingSkillsSection>
                    {this.props.courses && this.renderCourseList()}

                    <ButtonLink 
                        text="LOAD MORE"
                        link="/services/training-and-skills-development"
                    />
                </TrainingSkillsSection>
                {/* <section className="mb_infrastructure-section">
                    <Container>
                        <Row>
                            <Col>
                                <img className="mb_img" src={require('../assets/img/Infrastructure-Summit-Poster-Final.jpg')} alt="Infrastructure Summit" />
                            </Col>
                        </Row>
                    </Container>
                </section> */}
                
                {/*<SocialFeedSection />*/}
            </Nav>
        )        
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()} 
            </React.Fragment>            
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['home'],
        courses: state.coursesHomeState.data
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails,
    fetchCoursesHome
})(Home)