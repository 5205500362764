import {
    FETCH_HOME_SLIDES
} from '../actions/types'

export default (state = [], action) => {
    switch (action.type) {
        case FETCH_HOME_SLIDES:
            return action.payload
        default:
            return state
    }
}