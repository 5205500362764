import {
    FETCH_MEMBER_PROJECT_GALLERY
} from '../actions/types'

const initialState = {}

const memberProjectGalleryReducer = (state = initialState, action) => {    
    switch (action.type){
        case FETCH_MEMBER_PROJECT_GALLERY:
            return { ...state, [action.id]: action.payload }
        default:
            return state
    }
}

export default memberProjectGalleryReducer