import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NewsItem from '../items/NewsItem'
import {HeadingSecondaryWhite} from '../headings'
import {ButtonLinkWhite} from '../buttons'
import BackgroundImage from '../../assets/img/latest-news.jpg'
import {connect} from 'react-redux'
import {fetchNewsHome} from '../../actions'

class NewsHomeSection extends React.Component{

    componentDidMount(){
        if(!this.props.news){
            this.props.fetchNewsHome(null)
        }
    }

    renderList(){
        return this.props.news.data.map((item, index) => {
            if(item.headline){
                return (
                    <React.Fragment key={index}>
                        <Col sm={12} md={6} lg={4} xl={4} >
                            <NewsItem
                                date={item.published}
                                text={item.headline}
                                link={`/news/${item.slug}`}
                            />
                        </Col>
                    </React.Fragment>
                )
            }        
            return <React.Fragment></React.Fragment>    
        })
    }

    render(){
        const style = {
            backgroundImage: `url(${BackgroundImage})`
        }
        return(
            <section className="mb_news-home-section" style={style}>
                <div className="mb_overlay-dark">
                    <Container>
                        <Row>
                            <Col>
                                <HeadingSecondaryWhite 
                                    text="LATEST NEWS"
                                />
                                <div className="mb_border-bottom-center"></div>
                            </Col>
                        </Row>
                        <Row>
                            {this.props.news && this.renderList()}
                            <ButtonLinkWhite 
                                text="LOAD MORE"
                                link="/news"
                            />
                        </Row>
                    </Container>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        news: state.newsHomeState.data
    }
}

export default connect(mapStateToProps, {
    fetchNewsHome
})(NewsHomeSection)