import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import {connect} from 'react-redux'
import {fetchManagement, fetchPageDetails} from '../actions'
import {BASE_URL} from '../path'

//sections
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'

//items
import RoundImageItem from '../components/items/RoundImageItem'

function extractHostname(url) {
    let hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];

    } else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
}

function prependingUrl(url) {
    let link;

    if (!url.match(/^[a-zA-Z]+:\/\//)){
        link = 'http://' + url;
    } else {
        link = url;
    }

    return link;
}

class Management extends React.Component{

    componentDidMount(){
        // if(!this.props.data){
        //     this.props.fetchManagement(null)
        // }
        this.props.fetchManagement()
        this.props.fetchPageDetails('management')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderSeniorOfficers(managers){
        return managers.map((item, index) => {
            if(item.image){
                return(
                    <React.Fragment key={index}>
                        <RoundImageItem
                            image={`${BASE_URL}${item.image}`}
                            title={item.name}
                            text={item.position}
                        />
                    </React.Fragment>
                )
            }
            return <React.Fragment key={index}></React.Fragment> 
        })
    }

    SeniorOfficers(){
        return this.props.data.map((item, index) => {
            if(item.department === 'Officers'){
                if(item.management) {
                    return  this.renderSeniorOfficers(item.management)
                } else {
                    return <React.Fragment></React.Fragment>
                }
            }
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderOfficers(managers){
        return managers.map((item, index) => {
            return(
                <React.Fragment key={index}>
                    <tr key={index}>
                        <td>{item.position}</td>
                        <td>{item.name}</td>
                        <td>{item.company_represented}</td>
                        <td>
                            { item.link &&
                            <a href={prependingUrl(item.link)} target="_blank" title={item.company_represented} el="noopener noreferrer">{extractHostname(item.link)}</a>
                            }
                        </td>
                    </tr>
                </React.Fragment>  
            )
        })
    }

    renderOfficersTable(){
        return this.props.data.map((item, index) => {
            if(item.department === 'Officers'){
                if(item.management) {
                    return  this.renderOfficers(item.management)
                } else {
                    return <React.Fragment></React.Fragment>
                }
            }
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderManagers(managers, department){
        return managers.map((item, index) => {

            return(
                <React.Fragment key={index}>
                    <tr key={index}>
                        <th>{department}</th>
                        <td>{item.name}</td>
                        <td>{item.company_represented}</td>
                        <td>
                            { item.link &&
                            <a href={prependingUrl(item.link)} target="_blank" title={item.company_represented} el="noopener noreferrer">{extractHostname(item.link)}</a>
                            }
                        </td>
                    </tr>
                </React.Fragment>  
            )
        })
    }

    renderManagementTables(){
        return this.props.data.map((item, index) => {
            if(item.department !== 'Officers'){
                if(item.management) {
                    return (
                        <React.Fragment key={index}>
                            <Table responsive="lg" className="mb_management-tables">
                                {this.renderManagers(item.management, item.department)}
                            </Table>
                        </React.Fragment>
                    )
                } else {
                    return <React.Fragment></React.Fragment>
                }
            }       
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderPageContent(){

        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <section className="mb_management-section">
                    <Container>

                        <Row>
                            {this.props.data && this.SeniorOfficers()}
                        </Row>

                        <Row>
                            <Col>
                                 <Table responsive="lg">
                                    <tr>
                                        <th>OFFICERS</th>
                                        <th>NAME</th>
                                        <th>COMPANY REPRESENTED</th>
                                        <th>COMPANY WEBSITE</th>
                                    </tr>
                                    {this.props.data && this.renderOfficersTable()}
                                </Table> 
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {this.props.data && this.renderManagementTables()}
                            </Col>
                        </Row>
                        
                    </Container>
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.managementState,
        pageData: state.pageDetailsState['management']
    }
}

export default connect(mapStateToProps, {
    fetchManagement,
    fetchPageDetails
})(Management)