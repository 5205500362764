import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../assets/img/mba-logo@2x.jpg'

class Logo extends React.Component {
    render(){
        return(
            <Link to="/" className="mb_logo" title="Master Builders and Allied Trades Association Western Cape">
                <figure>
                    <img src={logo} alt="" />
                </figure>
            </Link>
        )
    }
}

export default Logo