import {
    FETCH_MEMBERS_COMPANY
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_MEMBERS_COMPANY:
            return {
                ...state,
                [action.payload.keywords]: action.payload.data
            }
        default:
            return state
    }
}