import React from 'react'
import SimpleSlider from '../SimpleSlider'
import BackgroundImageItem from '../items/BackgroundImageItem'
import {connect} from 'react-redux'
import {fetchHomeSlides} from '../../actions'

class TopBannerSliderSection extends React.Component {

    componentDidMount(){
        if(!this.props.slides){
            this.props.fetchHomeSlides(null)
        }
        
    }

    renderList(){
        console.log(this.props.slides)
        return this.props.slides.data.map((item, index) => {
            if(item.image){
                return (
                    <BackgroundImageItem 
                        key={index}
                        data={item}
                    />
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    render(){  
        return(
            <SimpleSlider>
                {this.props.slides && this.renderList()}
            </SimpleSlider>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        slides: state.homeSlidesState.data
    }
}

export default connect(mapStateToProps, {
    fetchHomeSlides
})(TopBannerSliderSection)