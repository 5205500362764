import React from 'react'
import {NavLink, Link} from 'react-router-dom'
// import { HashLink as AnchorLink } from 'react-router-hash-link';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import AccordionContext from 'react-bootstrap/AccordionContext'

import facebookIcon from '../assets/img/icons/facebook-icon.svg'
import linkedinIcon from '../assets/img/icons/linkedin-icon.svg'
import twitterIcon from '../assets/img/icons/twitter-icon.svg'
import instagramIcon from '../assets/img/icons/instagram-icon.svg'
import goldFacebookIcon from '../assets/img/icons/gold-facebook-icon.svg'
import goldLinkedinIcon from '../assets/img/icons/gold-linkedin-icon.svg'
import goldTwitterIcon from '../assets/img/icons/gold-twitter-icon.svg'
import goldInstagramIcon from '../assets/img/icons/gold-instagram-icon.svg'
import officeHoursIcon from '../assets/img/icons/office-hours.svg'
import telephoneIcon from '../assets/img/icons/telephone.svg'
import emailIcon from '../assets/img/icons/email.svg'
import pdf1 from '../assets/pdfs/Master-Builders-Western-Cape-Constitution-2021_FINAL.pdf'

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = React.useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      className={`${isCurrentEventKey ? 'mb_change-arrow-up' : ''}`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}


export class NavLinksWidescreen extends React.Component {

    componentDidMount(){
        if (window.location.href.indexOf('about') > -1) {
            document.getElementById('dropdown-about-button').style.backgroundColor = '#212931';
        } else if (window.location.href.indexOf('services') > -1) {
            document.getElementById('dropdown-member-services-button').style.backgroundColor = '#212931';
        } else if (window.location.href.indexOf('faq') > -1) {
            document.getElementById('dropdown-fag-button').style.backgroundColor = '#212931';
        }
    }

    render(){
        return(
            <React.Fragment>
                <li>
                    <NavLink exact to="/" activeClassName="mb_active">Home</NavLink>
                </li> 
                <li>
                    <DropdownButton id="dropdown-about-button" title="About">
                        <Link to="/about" >About Us</Link>
                        <Link to="/about/benefits-of-membership">Benefits Of Membership</Link>
                        <Link to="/about/code-of-practice">Code Of Practice</Link>
                        <Link to="/about/corporate-partners">Corporate Partners</Link>
                        <Link to="/about/corporate-social-responsibility">Corporate & Social Responsibility</Link>
                        <Link to="/about/management">Management</Link>
                        <Link to="/about/staff">Staff</Link>
                        <a href={pdf1} target="_blank" title="Download" rel="noopener noreferrer" download="Constitution">Constitution</a>
                    </DropdownButton>
                </li>
                <li>
                    <DropdownButton id="dropdown-member-services-button" title="Member Services">
                        <Link to="/services" >Contract Document Pricelist</Link>
                        {/*<Link to="/">Contractual & Legal</Link>*/}
                        <Link to="/services/joint-practice-commitee">Joint Practice Committee</Link>
                        <Link to="/services/mba-insurance">MBA Insurance</Link>
                        <Link to="/services/occupational-health-and-safety">Occupational Health and Safety</Link>
                        <Link to="/services/contacts">Telephone Numbers | Adjudicators | Inspectors</Link>
                        <Link to="/services/training-and-skills-development">Training & Skills Development</Link>
                    </DropdownButton>
                </li>
                <li>
                    <NavLink to="/news" activeClassName="mb_active">News</NavLink>
                </li>
                <li>
                    <NavLink to="/events" activeClassName="mb_active">Events</NavLink>
                </li>
                <li>
                    <DropdownButton id="dropdown-fag-button" title="FAQ">
                        <Link to="/faq" >Frequently Asked Questions</Link>
                        <Link to="/faq/guidelines-to-successful-building">Guidelines To Successful Building</Link>
                    </DropdownButton>
                </li>
                
                <li>
                    <NavLink to="/members" className="mb_link-color" activeClassName="mb_active">Find a Member</NavLink>
                </li>

               {/* <li>
                    <NavLink to="/employment-opportunities" className="_emOp-nav-link" activeClassName="mb_active">Employment<br />Opportunities</NavLink>
                </li>*/}
                
                <li>
                    <NavLink to="/contact" activeClassName="mb_active">Contact Us</NavLink>
                </li>
            </React.Fragment>      
        )
    }
}

export class NavLinksMobile extends React.Component {
    render(){
        return(
            <React.Fragment>
                <Accordion>
                <li className="mb_mobile-nav-link">
                    <NavLink exact to="/" activeClassName="mb_active">Home</NavLink>
                </li>
                <li className="mb_mobile-nav-accordion">                   
                    <ContextAwareToggle eventKey="0">
                        About <div className="mb_arrow-down"></div>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="0">
                        <Link to="/about" >About Us</Link>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="0">
                        <Link to="/about/benefits-of-membership">Benefits Of Membership</Link>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="0">
                        <Link to="/about/code-of-practice">Code Of Practice</Link>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="0">
                        <Link to="/about/corporate-partners">Corporate Partners</Link>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="0">
                        <Link to="/about/corporate-social-responsibility">Corporate & Social Responsibility</Link>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="0">
                        <Link to="/about/management">Management</Link>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="0">
                        <Link to="/about/staff">Staff</Link>
                    </Accordion.Collapse>
                </li>
                <li className="mb_mobile-nav-accordion">
                    <ContextAwareToggle eventKey="1">
                        Member Services <div className="mb_arrow-down"></div>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="1">
                        <Link to="/services" >Contract Document Pricelist</Link>
                    </Accordion.Collapse>
                    {/*<Accordion.Collapse eventKey="1">
                        <Link to="/">Contractual & Legal</Link>
                    </Accordion.Collapse>*/}
                    <Accordion.Collapse eventKey="1">
                        <Link to="/services/joint-practice-commitee">Joint Practice Committee</Link>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="1">
                        <Link to="/services/mba-insurance">MBA Insurance</Link>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="1">
                        <Link to="/services/occupational-health-and-safety">Occupational Health and Safety</Link>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="1">
                        <Link to="/services/contacts">Telephone Numbers | Adjudicators | Inspectors</Link>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="1">
                        <Link to="/services/training-and-skills-development">Training & Skills Development</Link>
                    </Accordion.Collapse>
                </li>
                <li className="mb_mobile-nav-link">
                    <NavLink to="/news" activeClassName="mb_active">News</NavLink>
                </li>
                <li className="mb_mobile-nav-link">
                    <NavLink to="/events" activeClassName="mb_active">Events</NavLink>
                </li>
                <li className="mb_mobile-nav-accordion">
                    <ContextAwareToggle eventKey="3">
                        FAQ <div className="mb_arrow-down"></div>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="3">
                        <Link to="/faq" >Frequently Asked Questions</Link>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="3">
                        <Link to="/faq/guidelines-to-successful-building">Guidelines To Successful Building</Link>
                    </Accordion.Collapse>     
                </li>
                <li className="mb_mobile-nav-link">
                    <NavLink to="/members" className="mb_link-color" activeClassName="mb_active">Find a Member</NavLink>
                </li>
                {/*<li className="mb_mobile-nav-link">
                    <NavLink to="/employment-opportunities" activeClassName="mb_active">Employment Opportunities</NavLink>
                </li>*/}
                <li className="mb_mobile-nav-link">
                    <NavLink to="/contact" activeClassName="mb_active">Contact Us</NavLink>
                </li>
                </Accordion>
            </React.Fragment>      
        )
    }
}



export class ContactLinks extends React.Component {
    render(){
        return(
            <React.Fragment>
                <li>
                    <img src={officeHoursIcon} alt="Office Hours - Master Builders and Allied Trades Association Western Cape" />
                    <div>
                        <h5 className="mb_heading-8">Office Hours</h5>
                        <span className="mb_paragraph" style={{
                            lineHeight: '15px',
                            display: 'block'
                        }}>
                            Monday - Thursday: 08:30 – 16:30<br/>
                            Friday: 08:30 – 15:30
                        </span>
                    </div>
                </li>
                <li>
                    <img src={telephoneIcon} alt="Telephone - Master Builders and Allied Trades Association Western Cape" />
                    <div>
                        <h5 className="mb_heading-8">Telephone</h5>
                        <a href="tel:021 685 2625" className="mb_paragraph" title="Call us">021 685 2625</a>
                    </div>    
                </li>
                <li>
                    <img src={emailIcon} alt="Our Email - Master Builders and Allied Trades Association Western Cape" />
                    <div>
                        <h5 className="mb_heading-8">Our Email</h5>
                        <a href="mailto:info@mbawc.org.za?subject=MBAWC Enquiry" className="mb_paragraph" title="Send us an e-mail">info@mbawc.org.za</a>
                    </div>
                </li>
            </React.Fragment>      
        )
    }
}

export class SocialMediaIconsLinks extends React.Component {
    render(){
        return(
            <React.Fragment>
                <li>
                    <a href="https://www.facebook.com/Master-Builders-Association-Western-Cape-208754575843670/" target="_blank" rel="noopener noreferrer">
                        <img src={facebookIcon} alt="Facebook" />
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/mba-western-cape-263001151/" target="_blank" rel="noopener noreferrer">
                        <img src={linkedinIcon} alt="Instagram" />
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/mba_assoc" target="_blank" rel="noopener noreferrer">
                        <img src={twitterIcon} alt="Twitter" />
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/masterbuildersassociationwc/" target="_blank" rel="noopener noreferrer">
                        <img src={instagramIcon} alt="Instagram" />
                    </a>
                </li>
            </React.Fragment>
        )
    }
}

export class GoldSocialMediaIconsLinks extends React.Component {
    render(){
        return(
            <React.Fragment>
                <li>
                    <a href="https://www.facebook.com/Master-Builders-Association-Western-Cape-208754575843670/" target="_blank" rel="noopener noreferrer">
                        <img src={goldFacebookIcon} alt="Facebook" />
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/mba-western-cape-263001151/" target="_blank" rel="noopener noreferrer">
                        <img src={goldLinkedinIcon} alt="LinkedIn" />
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/mba_assoc" target="_blank" rel="noopener noreferrer">
                        <img src={goldTwitterIcon} alt="Twitter" />
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/masterbuildersassociationwc/" target="_blank" rel="noopener noreferrer">
                        <img src={goldInstagramIcon} alt="Instagram" />
                    </a>
                </li>
            </React.Fragment>
        )
    }
}

export class CopyWrightLinks extends React.Component {
    render(){
        return(
            <React.Fragment>
                <li className="mb_copywright-links"> 
                    <Link to="/">2020 © Master Builders Association Western Cape</Link>
                    <span> | </span>
                    <Link to="/website-disclaimer">Website Disclaimer</Link> 
                    <span> | </span>
                    {/*<Link to="/">PAIA</Link>
                    <span> | </span>*/}
                    <a href="https://optimalonline.co.za" target="_blank"  rel="noopener noreferrer">Powered by <strong> OPTIMAL ONLINE</strong></a>
                </li>
            </React.Fragment>
        )
    }
}