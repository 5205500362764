import React from 'react'
import {
    Link
} from 'react-router-dom'
import history from '../history'

export class Button extends React.Component {

    onClick(){
        history.push('/')
    }

    render(){
        return(
            <button className="mb_button" onClick={this.props.onClick ? this.props.onClick : this.onClick}>{this.props.text ? this.props.text : "Button"}</button>
        )
    }    
}

export class ButtonFillColor extends React.Component {

    render(){
        return(
            <Link className="mb_button-fill" to={this.props.link ? this.props.link : "/"} title={this.props.title ? this.props.title : ''}>{this.props.text ? this.props.text : "Button"}</Link>
        )
    }    
}

export class ButtonLink extends React.Component {
    render(){
        return(
            <Link className="mb_button" to={this.props.link ? this.props.link : "/"} title={this.props.title ? this.props.title : ''}>{this.props.text ? this.props.text : "Button"}</Link>
        )
    }    
}

export class ButtonLinkWhite extends React.Component {
    render(){
        return(
            <Link className="mb_button-white" to={this.props.link ? this.props.link : "/"} title={this.props.title ? this.props.title : ''}>{this.props.text ? this.props.text : "Button"}</Link>
        )
    }    
}

export class ButtonLinkGoldFill extends React.Component {
    render(){
        return(
            <Link className="mb_button-gold-fill" to={this.props.link ? this.props.link : "/"} title={this.props.title ? this.props.title : ''}>{this.props.text ? this.props.text : "Button"}</Link>
        )
    }    
}

export class MenuButton extends React.Component {
    render(){
        return(
            <button className={`mb_menu-button ${this.props.menu ? 'open' : ''}`} onClick={this.props.onClick} title={this.props.menu ? 'Close menu' : 'Open Menu'}>
                <div className="mb_inner">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
        )
    }    
}