import {
    FETCH_JOINT_PRACTICE_COMMITTEE
} from '../actions/types'

export default (state = [], action) => {
    switch (action.type) {
        case FETCH_JOINT_PRACTICE_COMMITTEE:
            return action.payload
        default:
            return state
    }
}