import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class PageNotFound extends React.Component{

    renderPageContent(){
        return(
            <Nav>
                <section className="mb_page-not-found-section text-center">
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={12} >
                                <h1 className="mb_heading-1-2">404 | Page Not Found</h1>
                                <div className="mb_border-bottom-center"></div>
                                <p className="mb_paragraph-3">Sorry, the page you are looking for could not be found.</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>404 Page Not Found | Master Builders and Allied Trades Association Western Cape</title>
                    <meta name="description" content="Master Builders and Allied Trades Association Western Cape" />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

export default PageNotFound