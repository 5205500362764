import React from 'react'
import Col from 'react-bootstrap/Col';

function extractHostname(url) {
    let hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];

    } else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
}

function prependingUrl(url) {
    let link;

    if (!url.match(/^[a-zA-Z]+:\/\//)){
        link = 'http://' + url;
    } else {
        link = url;
    }

    return link;
}

class RoundImageItem extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            link: this.props.link ? this.props.link : null,
            text: this.props.text ? this.props.text : null
        }
    }
    render(){
        const {image, title} = this.props
        const linkText = extractHostname(`${this.state.link}`)
        const link = prependingUrl(`${this.state.link}`)
        return(
            <Col sm={12} md={4} lg={4} xl={4}>
                <figure className="mb_round-image-item">
                    <div className="mb_round-image">
	                   <img src={image}  alt={`${title}`} title={`${title}`} />
                    </div>
	                <figcaption>
	                    <h4 class="mb_heading-3-1">{title}</h4>
	                </figcaption>
	                {
	                    this.state.link && 
	                    <a href={link} target="_blank" className="mb_paragraph-3" title={title} rel="noopener noreferrer">{linkText}</a>
	                }
	                {
	                    this.state.text && 
	                    <span className="mb_paragraph-3">{this.state.text}</span>
	                }
                </figure>
            </Col> 
        )
    }
}

export default RoundImageItem