import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import EventsListSection from '../components/sections/EventsListSection'
import EventsPastSection from '../components/sections/EventsPastSection'
import EventsUpcomingSection from '../components/sections/EventsUpcomingSection'
import EventsYearSection from '../components/sections/EventsYearSection'
import {connect} from 'react-redux'
import {fetchPageDetails, fetchEmploymentOpportunities} from '../actions'
import {BASE_URL} from '../path'

class EmploymentOpportunities extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('employment-opportunities')
        this.props.fetchEmploymentOpportunities()
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderList(){ 
      return (
        <ul className='_em-op-ul'>
          {this.props.emOp.length === 0 && <li>No Records Found</li>}
          {this.props.emOp.map(({id,title,path}) => {
            return (
              <li className='_em-op-li' key={id}>
                <a href={BASE_URL + path} target="_blank" rel="noreferrer">{title}</a>
              </li>
            )
          })}
        </ul>
      )
    }

    renderPageContent(){
        return(
            <Nav>
               <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}

                <section className="_em-op-section">
                  <Container>
                      <Row>
                          <Col>
                            {this.renderList()}
                          </Col>
                      </Row>
                  </Container>
              </section>
                
                
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['employment-opportunities'],
        emOp: state.employmentOpportunitiesState,
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails,
    fetchEmploymentOpportunities
})(EmploymentOpportunities)