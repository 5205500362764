import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux'
import {fetchPageDetails} from '../actions'

class WebsiteDisclaimer extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('website-disclaimer')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderPageContent(){
        return(
            <Nav>
                 <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <section className="mb_about-section">
                    {/*<Container>
                        <Row>
                            <Col>
                                <p className="mb_paragraph">By using this website you acknowledge and agree to the following;</p>

                                <p className="mb_paragraph">Use of this website and related products and/or services shall be governed by, and construed in all respects in accordance with the laws of South Africa, and subject to the exclusive jurisdiction of the South African courts.</p>

                                <p className="mb_paragraph">Use of this website or information, products and services available on this website is at the user’s own risk.</p>

                                <p className="mb_paragraph">Notwithstanding the provisions of section 43(5) and 43(6) of the Electronic Communications and Transaction Act, MBA Western Cape accepts no liability whatsoever relating to any loss, expense, claim or damage, whether direct, indirect or consequential, arising from the information on this Website, your use of this Website or any actions or transactions resulting therefrom, even if MBA Western Cape has been advised of the possibility of such loss, expense, claim or damages.</p>

                                <p className="mb_paragraph">MBA Western Cape makes no presentation or warranty, whether express or implied, as to the operation, integrity, availability or functionality of this Website or as to the accuracy, completeness or reliability of any information obtained from this Website.</p>  

                                <p className="mb_paragraph">MBA Western Cape also makes no warranty or representation, whether express or implied, that the products, information or files available on this Website are free of viruses, destructive materials or any other data or code which is able to corrupt, compromise or jeopardise the operation or content of a computer system, computer network or your hardware or software.</p>

                                <p className="mb_paragraph">You accept all risk associated with the existence of such viruses, destructive materials or any other data or code which is able to corrupt, compromise or jeopardise the operation or content of a computer system, computer network or your hardware or software. MBA Western Cape accepts no responsibility for any errors or omissions on this Website.</p>

                                <p className="mb_paragraph">MBA Western Cape may, in its sole discretion, at any time, suspend or terminate the operation of this website or any of the products or services provided in terms of this Website, without prior notice.</p>

                                <p className="mb_paragraph">Notwithstanding the fact that this Website may refer to and/or provide links to other websites, your use of such other websites is entirely at your own risk and MBA Western Cape is not responsible for any loss, expense, claim or damage, whether direct, indirect or consequential, arising from your use of such other Website. MBA Western Cape also does not endorse, warrant or make any representations about the content, products, services, security or reliability of such other websites.</p>
                            </Col>
                        </Row>
                    </Container>*/}
                </section>
            </Nav>
        )
    }

    render(){
        return(
             <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['website-disclaimer']
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails
})(WebsiteDisclaimer)