import React from 'react'
import history from '../../history'
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux'
import {fetchMembersCategories} from '../../actions'

class SearchEventYear extends React.Component{

    constructor() {
        super();
        const thisYear = (new Date()).getFullYear();
        this.state = {
            year: '',
            thisYear: thisYear,
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleSubmit = event => {
        if(this.state.year !== ''){
            history.push(`/events?year=${this.state.year}`)
        } else {
            history.push('/events')
        }
    };

    componentDidMount(){
        if(!this.props.categories){
            this.props.fetchMembersCategories(null)
        }

    }

    renderYears(){
        const {thisYear} = this.state;
        const startYear = 2015;
        const options = [];
        
        for (let i = startYear; i <= thisYear; i++) {
            const year = i;
            options.push(<option value={year}>{year}</option>);
        }
        return (
            <React.Fragment> 
                {options}
            </React.Fragment>
        );
    }

    render(){
        return(                     
            <Col sm={12} lg={6}>
                <form className="mb_search-form-date" onSubmit={this.handleSubmit}>
                    <div className="mb_form-input">
                        <label className="mb_heading-9" for="year">Search by Year</label>
                        <select 
                            className="mb_form-control" 
                            name="year"
                            value={this.state.year}
                            onChange={this.handleChange}
                        >
                            <option value="null" >Select Year</option>
                            {this.renderYears()}
                        </select>
                    </div>
                    <input className="mb_button" type="submit" value="SEARCH" />
                </form>
            </Col>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        categories: state.membersCategoriesState.data
    }
}

export default connect(mapStateToProps, {
    fetchMembersCategories
})(SearchEventYear)