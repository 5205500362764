import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'
import SecondaryHeadingSection from '../components/sections/SecondaryHeadingSection'
import BenefitsSection from '../components/sections/BenefitsSection'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux'
import {fetchPageDetails} from '../actions'

//items
import ImageItem from '../components/items/ImageItem'
import BenefitsItem from '../components/items/BenefitsItem'

//images
import logo from '../assets/img/master-builders-insurance@2x.jpg'
import icon1 from '../assets/img/icons/tailor-made-icon.svg'
import icon2 from '../assets/img/icons/competitive-pricing-icon.svg'
import icon3 from '../assets/img/icons/professional-advice-icon.svg'
import icon4 from '../assets/img/icons/qualified-staff-icon.svg'
import icon5 from '../assets/img/icons/toll-free-assistance-icon.svg'
import rbsLogo from '../assets/img/rbs-logo@2x.jpg'


class MbaInsurance extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('mba-insurance')
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                {/*<section className="mb_insurance-section">
                    <Container>
                        <Row>
                            <Col>
                                <ImageItem 
                                    image={logo}
                                    alt="Master Builders Insurance"
                                />
                                <h3 class="mb_heading-3-1">WELCOME TO MASTER BUILDERS INSURANCE</h3>
                                <p className="mb_paragraph">As a builder, contractor or construction professional you are exposed to of the risks of working on or around a construction site. They are inherently dangerous places for both your team and yourself from both a physical and a business perspective. Therefore it is imperative that you are correctly covered for every eventuality.</p>

                                <p className="mb_paragraph">The MBI (Master Builders Insurance) product has been designed with the single view of helping you safeguard your business and yourself. Whilst risk and losses are inevitable the aim of MBI is to offer protection and peace of mind during the stressful recovery period after a loss.</p>

                                <p className="mb_paragraph">Let the issue of business disruption after a loss, become a thing of the past with MBI.</p> 
                            </Col>
                        </Row>
                    </Container>
                </section>*/}
                <section className="mb_health-safety-secondary-heading-section text-center">
                    <Container>
                        <Row>
                            <Col>
                                <h3 class="mb_heading-1-2 text-center">BENEFITS OF MBI</h3>
                                <div className="mb_border-bottom-center"></div>
                                <p className="mb_paragraph-2"></p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <BenefitsSection>
                    <BenefitsItem
                        image={icon1}
                        title="TAILOR-MADE INSURANCE SOLUTIONS TO SUIT YOUR SPECIFIC NEEDS"
                        description=""
                     />
                     <BenefitsItem
                        image={icon2}
                        title="COMPETITIVE PRICING WITH ADDITIONAL BENEFITS"
                        description=""
                     />
                     <BenefitsItem
                        image={icon3}
                        title="PERSONALIZED PROFESSIONAL ADVICE"
                        description=""
                     />
                     <BenefitsItem
                        image={icon4}
                        title="QUALIFIED STAFF WITH A “CAN DO” ATTITUDE"
                        description=""
                     />
                     <BenefitsItem
                        image={icon5}
                        title="24/7 ASSISTANCE ON TOLL FREE NUMBER"
                        description=""
                     />

                     <Col xs={12} sm={12} lg={12} className="mb_center-text">
                        <p className="mb_paragraph">For more information visit Master Builders Insurance at <span className="clearfix"></span>
                        <a href="http://www.masterbuildersinsurance.co.za/" target="_blank" title="Master Builders Insurance" el="noopener noreferrer">www.masterbuildersinsurance.co.za</a>
                        </p>

                        <p className="mb_paragraph">In association with RBS. An authorised Financial Services Provider.</p> 

                        <ImageItem 
                            image={rbsLogo}
                            alt="Master Builders Insurance"
                        />
                    </Col>
                </BenefitsSection>
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['mba-insurance']
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails
})(MbaInsurance)