import {
    FETCH_MEMBERS_CATEGORIES
} from '../actions/types'

export default (state = [], action) => {
    switch (action.type) {
        case FETCH_MEMBERS_CATEGORIES:
            return action.payload
        default:
            return state
    }
}