import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import {connect} from 'react-redux'
import {fetchPageDetails, fetchCourses} from '../actions'
import {Pagination} from 'react-laravel-paginex'
import {BASE_URL} from '../path'

//sections
import TrainingSkillsSection from '../components/sections/TrainingSkillsSection'
import HeadingSection from '../components/sections/HeadingSection'
import BodyArticleSection from '../components/sections/BodyArticleSection'

//items
import TrainingSkillsItem from '../components/items/TrainingSkillsItem'

//images
import placeholderImage from '../assets/img/placeholders/products.jpg'

class TrainingSkills extends React.Component{

    componentDidMount(){
        this.props.fetchPageDetails('training-and-skills-development')
        if(!this.props.courses){
            this.props.fetchCourses(null)
        }
    }

    renderCourseList(){
        return this.props.courses.data.map((item, index) => {
            if(item.title){
                return (
                    <React.Fragment key={index}>
                        <TrainingSkillsItem 
                            image={`${item.image ? `${BASE_URL}${item.image}` : placeholderImage}`}
                            title={item.title}
                            subtitle={item.subtitle}
                            description={item.description}
                            body={item.body}
                            contact_person={item.contact_person}
                            email={item.email}
                            pdf={`${item.pdf ? `${BASE_URL}${item.pdf}` : '/'}`}
                        />
                    </React.Fragment>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderBody(){
        if(this.props.pageData.data.body) {
            return(
                <React.Fragment>
                    <BodyArticleSection 
                        body={this.props.pageData.data.body}
                    />
                </React.Fragment>
            )
        }
        return <React.Fragment></React.Fragment>
    }

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline={this.props.pageData && this.props.pageData.data.headline}
                    text={this.props.pageData && this.props.pageData.data.subheading}
                />
                {this.props.pageData && this.renderBody()}
                <TrainingSkillsSection>
                    
                    {this.props.courses && this.renderCourseList()}
                    <Pagination changePage={this.props.fetchCourses} data={this.props.courses} containerClass="mb_pagination" />
                </TrainingSkillsSection>
            </Nav>
        )        
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageData && this.props.pageData.data.seo_title}</title>
                    <meta name="description" content={this.props.pageData && this.props.pageData.data.seo_meta_description} />
                    <meta name="keywords" content={this.props.pageData && this.props.pageData.data.seo_meta_keywords} />
                </Helmet>
                {this.renderPageContent()} 
            </React.Fragment>            
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        pageData: state.pageDetailsState['training-and-skills-development'],
        courses: state.coursesState.data
    }
}

export default connect(mapStateToProps, {
    fetchPageDetails,
    fetchCourses
})(TrainingSkills)